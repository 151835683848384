export const translation = {
    cz: {
        me: "Já",
        typeOfActivity: "Obor činnosti",
        workPosition: "Pozice",
        otherRegistration: "Další možnost",
        social: "Sociální média",
        certificateNumber: "já",
        aboutTheCompany: "O SPOLEČNOSTI",
        cooperation: "SPOLUPRÁCE",
        publicOffer: "Veřejná nabídka",
        termsOfUse: "Podmínky používání",
        setTheRenewShortcutToYourPhonesHomeScreenForQuickAccessToTheSite:
            "Nastavte si zástupce Renew na domovskou obrazovku telefonu pro rychlý přístup na stránky",
        share: "Sdílejte stránky",
        press: "Stiskněte tlačítko",
        addToScreen: "Přidat na obrazovku",
        homeScreen: "Domovská obrazovka",
        applicationIconWillAppearOnYourScreen:
            "Na obrazovce se objeví ikona aplikace, nyní si můžete kdykoli doplnit kosmetický nákup 😍 Užijte si používání 😊.",
        yourDiscount: "Vaše sleva",
        signIn: "Přihlásit se",
        byRegisteringYouAgreeTo: "Registrací souhlasíte s",
        privacyPolicy: "se zásadami ochrany osobních údajů",
        andTermsOfUse: "a podmínkami používání",
        whenRegisteringYourDataMayBeTransferredToThirdParties:
            "Při registraci mohou být vaše údaje předány třetím stranám.",
        basketEmpty: "KOŠÍK JE PRÁZDNÝ",
        volume: "Objem",
        total: "Celkem",
        continueShopping: "Pokračovat v nákupu",
        placeAnOrder: "Objednat",
        withThisProductBuy: "S tímto produktem si kupte",
        noProductsAvailable: "Žádné produkty nejsou k dispozici",
        getMoreInformationAboutTheProduct: "Získejte více informací o produktu",
        theGoodsAreOutOfStock: "Zboží není skladem",
        quantity: "Množství",
        pcs: "ks",
        phoneConfirmation: "Telefonické potvrzení",
        ourTrainingEventsAreAConcentrationOfInformationAndAnIncredibleAtmosphere:
            "Naše vzdělávací akce jsou koncentrací informací a neuvěřitelné atmosféry. V nejbližší době vás bude kontaktovat manažer manažer vás bude kontaktovat, aby potvrdil vaši registraci.",
        afterCheckingTheDocumentsYouWillBeGrantedAccessToTheProfessionalsPersonalAccount:
            "Po kontrole dokumentů vám bude umožněn přístup k osobnímu účtu odborníka.",
        expectAConfirmationLetterToYourEmailShortly: "V nejbližší době očekávejte potvrzovací dopis na svůj e-mail.",
        quickOrder: "Rychlá objednávka",
        privateIndividual: "Soukromá osoba",
        organization: "Organizace",
        edrpouCode: "Kód EDRPOU",
        companyName: "Název společnosti",
        enterTheFormOfOwnership: "Zadejte formu vlastnictví",
        yourCartIsEmpty: "Váš košík je prázdný",
        documentType: "Typ dokumentu",
        bonusesUsed: "Použité bonusy",
        youHave: "Máte",
        "onTheBonusAccountYouCanWriteOffYourBonusUahInFullOrInPartToThisOrder":
            "na bonusovém účtu. Na tuto objednávku si můžete odepsat bonusové UAH v plné výši nebo částečně.",
        drivingToThePatient: "Doručení pro pacienta",
        thereWillBeNoInvoiceWithProfessionalPricesAsWellAsProfessionalManuals:
            "doručení bez faktury s uvedenou kupní cenou odborníka a bez tiskových materiálů.",
        price: "Cena",
        withYourDiscount: "s vaší slevou",
        distributor: "distributor",
        theProductHasBeenMovedTo: "Produkt byl přesunut do",
        theNewSeries: "nové řady",
        goTo: "Přejít na",
        question: "Otázka",
        answer: "Odpověď",
        reviews: "Recenze",
        tannicAcidAndVitaminCComponentsOfLongevity: "KYSELINA TŘÍSLOVÁ A VITAMIN C - SLOŽKY DLOUHOVĚKOSTI",
        uniqueInItsProperties:
            "Kyselina tříslová - jedinečná svými vlastnostmi - jako první na světě integrovaná laboratoří Alphascience - do produktů proti stárnutí s pomocí antioxidantu vitaminu C - přírodního",
        theDoubleActionOfAntioxidants:
            "konzervační látku. Dvojí působení antioxidantů chrání pokožku před nemocemi, bakteriemi a agresivními vlivy prostředí.",
        selectASection: "Vyberte si část",
        watchTheVideoBusinessCard: "Podívejte se na videovizitku",
        thisTrainingIsAvailableOnlyForSpecialists: "Toto školení je určeno pouze pro odborníky (kosmetology).",
        cost: "Náklady na",
        thisTrainingIsAlreadyAvailable: "Toto školení je již k dispozici",
        thereAreNoWebinars: "Nejsou k dispozici žádné webové semináře",
        webinars: "Webináře",
        movement: "Pohyb",
        expenditureInvoice: "Výdajová faktura",
        movingNumber: "Číslo pohybu",
        date: "Datum",
        client: "Klient",
        contactDetailsOfTheRecipient: "Kontaktní údaje příjemce",
        comment: "Komentář",
        comments: "Komentář",
        products: "Produkty",
        articleNo: "Artikl č.",
        productName: "Název výrobku",
        discount: "Sleva",
        amount: "Celková částka",
        title: "Název",
        myLearningPlatform: "Moje vzdělávací platforma",
        myOrders: "Moje objednávky",
        movingToTheWarehouse: "Přesun do skladu",
        expenditureInvoices: "Výdajové faktury",
        myWarehouse: "Můj sklad",
        warehouseBalances: "Zůstatky ve skladu",
        salesFromTheWarehouse: "Prodej ze skladu",
        submitASalesReport: "Odeslání hlášení o prodeji",
        movementOfGoods: "Pohyb zboží",
        sales: "prodej",
        displacement: "přemístění",
        view: "Zobrazit",
        remnants: "Zbytky",
        actualSales: "Skutečný prodej",
        totalAmount: "Celková částka",
        thereAreNoRecords: "Nejsou žádné záznamy",
        testResults: "Výsledky testů",
        selectedProducts: "Vybrané výrobky",
        noSelectedProducts: "Žádné vybrané produkty",
        save: "Uložit",
        edit: "Upravit",
        cancel: "zrušit",
        test: "test",
        amountToBePaid: "Částka, která má být vyplacena",
        downloadTheManual: "stáhnout příručku",
        thereIsNoManual: "stáhnout metodiku",
        thisUserDoesNotHaveAnyOrders: "Tento uživatel nemá žádné objednávky",
        yourDiscountThisMonth: "Vaše sleva v tomto měsíci",
        salesForTheCurrentMonth: "Tržby za aktuální měsíc",
        debt: "Dluh",
        distributorIdIsAUniquePersonalCodeThatConfirmsTheRegistrationOfABeautySpecialistWithTheCompany:
            "ID distributora je jedinečný osobní kód, který potvrzuje registraci kosmetického specialisty ve společnosti",
        beauticianIdIsAUniquePersonalCodeThatConfirmsTheRegistrationOfABeautySpecialistInTheCompany:
            "ID kosmetičky je jedinečný osobní kód, který potvrzuje registraci kosmetického specialisty ve společnosti",
        readMore: "číst více",
        theSiteIsUnderMaintenance: "Stránky jsou v údržbě",
        tryAgainLater: "Zkuste to později",
        beforeDownloadingTheFileYouNeedToSelectACityFromTheListProvided:
            "Před stažením souboru je třeba vybrat město z uvedeného seznamu",
        innovations: "Inovace",
        training: "ŠKOLENÍ",
        loyalty: "LOYALTY",
        productInTheNewSeries: "Výrobek z nové řady",
        inTheBasket: "V košíku",
        showAll: "Zobrazit vše",
        tableOfSizes: "Tabulka velikostí",
        learnMoreAboutTheProduct: "Další informace o produktu",
        dataForDelivery: "Údaje pro dodání",
        with: "s",
        patient: "pacienta",
        thePriceOfAPatient: "Cena pacienta",
        forOrdersOfTwoItemsOrMoreOneSampleAsAGift: "Při objednávce dvou a více kusů - Jeden vzorek jako dárek",
        showMore: "Zobrazit více",
        aboutUs: "O nás",
        basket: "KOŠÍK",
        thankYouForRegistering: "Děkujeme za registraci!",
        selectAnAddress: "Vyberte adresu",
        name: "Jméno",
        send: "Odeslat",
        time: "Čas",
        returnToBasket: "Zpět do košíku",
        codeFromSms: "Kód z SMS*",
        buy: "Koupit",
        ownershipCodes: "FOP, TOV, PT, PP, AT...",
        reportAvailability: "Nahlásit dostupnost",
        askAQuestion: "Položit dotaz",
        allNews: "Všechny novinky",
        announcement: "Oznámení",
        detailedDescriptionOfTheTraining: "Podrobný popis školení",
        purchaseTraining: "Nákup školení",
        downloadTheDocument: "Stáhněte si dokument",
        calculateSales: "Výpočet prodeje",
        createAReport: "Vytvoření přehledu",
        downloadSalesReport: "Stáhněte si zprávu o prodeji",
        downloadMore: "Stáhněte si více",
        writeAnAnswer: "Napište odpověď",
        returnToYourAccount: "Zpět na váš účet",
        termsOfParticipation: "Podmínky účasti",
        start: "Začněte na",
        enterTheNumberOfBonuses: "Zadejte počet bonusů",
        validationEmail: "Zadejte platnou e-mailovou adresu",
        validationLengthPhone: "Maximální délka telefonu :počet",
        validationMax: "Maximální počet znaků :count",
        validationMin: "Minimální počet znaků :count",
        validationRequired: "Vyplňte toto pole",
        validationSameAs: "Hesla se neshodují!",
        failLoadFiles: "Odeslání souboru se nezdařilo",
        emailActivationProfile: "Vám, profesionálnímu klientovi, bude e-mailem zaslán potvrzovací dopis.",
        registrationDistributor: "Registrace distributora",
        registrationProfessional: "Registrace profesionálního distributora",
        lastName: "Příjmení",
        country: "Země",
        city: "Město",
        maxFileLoading: "Vybrat soubor / max. 5 MB",
        phone: "Telefonní číslo",
        work: "Pracovní adresa, jméno",
        approveDistributorFile:
            "Pro potvrzení, že jste distributorem, přiložte příslušné dokumenty: diplomy, osvědčení atd.",
        approveProfessionalFile:
            "Chcete-li potvrdit, že jste kosmetička, přiložte příslušné dokumenty: diplom kosmetičky a doklad totožnosti.",
        takeCity: "Vyberte město",
        takeBranch: "Výběr pobočky",
        takeCountry: "Výběr země",
        takeDepartment: "Vyberte pobočku",
        street: "Ulice",
        building: "Dům",
        numberBuilding: "Číslo domu",
        flat: "Apartmán",
        numberFlat: "Číslo bytu",
        value: "Objem",
        article: "Artikl",
        errorCodeDistributor: "Neplatný kód distributora",
        errorCodeProfessional: "Neplatný kód kosmetičky",
        successChangeCosmetolog: "ID kosmetičky bylo úspěšně aktualizováno",
        count: "Množství",
        priceWithDiscount: "Cena se slevou",
        priceWithoutDiscount: "Cena bez slevy",
        sum: "Celková částka",
        emptyBasket: "Košík je prázdný",
        enterIdDistributor: "Zadejte ID distributora",
        enterIdProfessional: "Zadejte ID profesionála",
        sumOrder: "Výše objednávky",
        discountDistributor: "Sleva pro distributora",
        discountProfessional: "Sleva pro odborníka",
        discountClient: "Sleva pro zákazníka",
        totalToPay: "Celková částka k zaplacení",
        payByBonuses: "Platba s bonusy",
        makeOrder: "Objednat",
        contactData: "Kontaktní informace",
        typeDelivery: "Způsob doručení",
        free: "Zdarma",
        grn: "UAH",
        contactDataRecipient: "Kontaktní údaje příjemce",
        whoRecipient: "Kdo je příjemcem objednávky?",
        anotherAddress: "Vyberte jinou adresu a příjemce",
        passportRule: "Příjemce objednávky od 5000₴ pouze s pasem (zákon ze dne 06.12.2019 č. 361-IX).",
        typePayment: "Způsob platby",
        distributorDocuments: "Doklady distributora",
        professionalDocuments: "Doklady profesionála",
        additionalInfo: "Další informace",
        additionalInfoHint:
            "(zde můžete zanechat svá přání ohledně vzorků nebo tiskových materiálů, které chcete obdržet ke své objednávce)",
        yourOrder: "Vaše objednávka",
        st: "ks",
        delivery: "Doručení",
        iHaveCode: "Mám promo kód",
        orderTwoProducts: "Pro objednávky dvou a více kusů",
        samplePresent: "Jeden vzorek jako dárek",
        notCallBack: "Nemusíte mi volat zpět. ",
        editOrder: "Upravte svou objednávku",
        approveOrder: "Potvrdit objednávku",
        completeOrder: "OBJEDNÁNÍ",
        showClientPrices: "Zobrazit ceny pro klienta",
        showProfessionalPrices: "Zobrazit ceny pro profesionála",
        downloadClientInvoice: "Stáhnout fakturu pro pacienta",
        downloadProfessionalInvoice: "Stáhnout fakturu pro profesionála",
        downloadPDF: "Stáhnout PDF",
        inStock: "Skladem",
        outOfStock: "Není skladem",
        distributorPrice: "Cena distributora",
        professionalPrice: "Profesionální cena",
        clientPrice: "Zákaznická cena",
        addToCard: "Přidat do košíku",
        fastBuy: "Rychlá objednávka",
        buyWithThis: "S tímto produktem zakoupeno",
        sendFeedback: "Zanechat hodnocení",
        sendQuestion: "Položit dotaz",
        yourMessage: "Vaše zpráva",
        writeFeedback: "Napište recenzi",
        feedback: "Komentář:",
        description: "Popis",
        readAllDescription: "Přečtěte si celý popis",
        recommendations: "Doporučení",
        readAllRecommendations: "Přečtěte si všechna doporučení",
        resetFilters: "Obnovit filtry",
        sort: "Seřadit podle",
        sort_types: {
            price_asc: "zvýšení cen",
            price_desc: "klesající cena",
            bestsellers: "propagační",
            newest: "nejvyšší prodej",
            with_promotions: "nové produkty",
        },
        filter: "Filtr",
        loadMore: "Zobrazit více",
        default: "Ve výchozím nastavení",
        priceSort: "ceny",
        bestSeller: "Nejprodávanější",
        news: "Nové položky",
        followInstagram: "Přihlásit se k odběru Instagram",
        clubRules: "Pravidla klubu",
        before: "před",
        after: "po",
        download: "Ke stažení na",
        workSchedule: "Pracovní plán",
        deliveryAndPayment: "Dodávka a platba",
        faq: "Často kladené otázky",
        profile: "Osobní účet",
        idDistributor: "ID distributora",
        idProfessional: "ID kosmetičky",
        distributorData: "Údaje o distributorovi",
        professionalData: "Osobní údaje odborníka",
        userData: "Osobní údaje",
        currentOrder: "Aktuální objednávka",
        historyOrder: "Historie objednávek",
        clubDistributor: "Moje kosmetičky s ID",
        clubProfessional: "Moji pacienti s ID",
        clubDistributors: "Moji kosmetologové s ID",
        clubProfessionals: "Moji pacienti s ID",
        favorite: "Můj seznam přání",
        logOut: "Odhlásit se",
        code: "Kód",
        phoneShort: "Tel.",
        selfPickup: "Vyzvednutí a doručení",
        repeatOrder: "Opakování objednávky",
        numberOrder: "Číslo objednávky",
        status: "Stav",
        empty: "Prázdný",
        bonusesValue: "Váš bonusový účet",
        bonuses: "body",
        distributorBonuses: "Bonusy pro distributory",
        professionalBonuses: "Profesionální bonusy",
        infoAboutDistributorIdUsage: "Informace o používání ID distributora",
        infoAboutProfessionalIdUsage: "Informace o používání ID profesionála",
        nameClient: "Příjmení a ID",
        bonusesFromOrder: "Bonusy z objednávek",
        professional: "professional",
        archiveAddresses: "Archiv adres",
        nameRecipient: "Křestní jméno příjemce",
        lastNameRecipient: "Příjmení příjemce",
        phoneRecipient: "Telefonní číslo příjemce",
        addAddress: "Přidání nové adresy",
        change: "Upravit",
        add: "PŘIDAT",
        documents: "Dokumenty / obrázky",
        contacts: "Kontaktujte nás",
        secondName: "Jméno a otcovské jméno",
        patronymic: "Střední jméno",
        activateAnotherId: "Aktivace dalšího ID",
        resultSearch: "Výsledky vyhledávání",
        anotherDivision: "Další sekce",
        posts: "Novinky",
        pageNotFoundDescription: "Stránka nebyla nalezena",
        backToHome: "Na hlavní stránku",
        error500: "Chyba serveru",
        politica: "Zásady ochrany osobních údajů",
        search: "Vyhledávání",
        defaultPrices: "Objednávka s cenami pro klienta",
        clientPricesPopup: "Objednávka s cenami pro pacienta",
        professionalPricesPopup: "Objednávka s cenami pro odborníka",
        buyOneClick: "Nákup na 1 kliknutí",
        addNewAddress: "Přidání nové adresy",
        pickAddress: "Výběr adresy a příjemce",
        restorePassword: "Změna hesla",
        changePassword: "Změna hesla",
        resetPassword: "Obnovení hesla",
        sendRequest: "Zanechat požadavek",
        enterNewId: "Zadejte nové ID",
        activate: "Aktivace",
        streetShort: "street",
        saveAddress: "Přidat adresu",
        clientDiscount: "Zákaznická sleva",
        totalSum: "Celková částka k zaplacení",
        enterPhone: "Zadejte své telefonní číslo",
        weCall: "Zavoláme vám zpět a provedeme vaši objednávku",
        workAndAddress: "Název a adresa salonu",
        enter: "Přihlášení",
        registration: "Zaregistrujte si účet",
        email: "e-mail",
        password: "Heslo",
        rememberMe: "Zapamatovat si mě",
        forgotPassword: "Zapomněli jste své heslo?",
        coupon: "Kupón",
        repeatPassword: "Obnovení hesla",
        registerPolitica: "Registrací souhlasíte se zásadami ochrany osobních údajů",
        registerPolitica2: "Během registrace mohou být vaše údaje předány třetím stranám",
        registered: "Jste zaregistrováni ?",
        enterToProfile: "Přihlaste se ke svému účtu",
        restInstructions: "Zadejte e-mail, který jste použili při registraci. Obdržíte e-mail s dalšími pokyny",
        emailExemple: "Example@gmail.com",
        sendMail: "Odeslat e-mail",
        backToLogin: "Zpět na autorizaci",
        restoreInstructions: "Vymyslete nové heslo. Musí obsahovat alespoň 6 znaků (A-z, 0-9).",
        newPassword: "Nové heslo",
        oldPassword: "Staré heslo",
        confirmPassword: "Potvrzení hesla",
        repeatNewPassword: "Opakování hesla",
        authWithNewPassword: "Přihlášení pomocí nového hesla",
        cancelRestore: "Zrušit změnu hesla",
        successRegistration: "Děkujeme za registraci",
        yourDistributorID: "Vaše ID distributora",
        yourProfessionalID: "Vaše profesní ID",
        successRemoveFromFavorite: "Úspěšně odstraněno z oblíbených položek",
        successAddToFavorites: "Přidáno do oblíbených",
        successAddBasket: "Přidáno do košíku",
        allAboutProduct: "Vše o produktu",
        composition: "Složení",
        recommendation: "Doporučení",
        feedbackAndQuestion: "Recenze a dotazy",
        home: "Úvodní stránka",
        catalog: "Katalog",
        successFeedbackSend: "Recenze úspěšně odeslána",
        successCreateOrder: "Objednávka úspěšně odeslána",
        successLogin: "Úspěšně autorizováno",
        succesFastBuyCreate: "Objednávka úspěšně uložena",
        noData: "Nejsou k dispozici žádná data",
        successQuestionSend: "Otázka úspěšně odeslána",
        successAddNewAddress: "Přidána nová adresa",
        successDeleteAddress: "Adresa úspěšně odstraněna",
        successChangeAddress: "Úspěšně změněná adresa",
        address: "Adresa",
        emailText: "Mail",
        phones: "Telefonní čísla",
        newAddress: "Nová adresa",
        anotherPerson: "Jiná osoba",
        successResetContact: "Kontaktní údaje byly úspěšně aktualizovány",
        requestSuccessSend: "Žádost byla odeslána",
        successSendFeedback: "Zpětná vazba byla úspěšně odeslána",
        successResetData: "Údaje byly úspěšně aktualizovány",
        register: "Zaregistrujte se nyní",
        moreDetailed: "Přečtěte si více",
        waitingForCall: "Čekání na hovor",
        successFastBuyCreate: "Rychlá objednávka",
        successRegisterWithDiscount: "Byla vám poskytnuta sleva 25 % podle zadaného profesního průkazu:",
        readAllSyllable: "Přečtěte si celé složení",
        hide: "Skrýt",
        successPayment: "Platba proběhla úspěšně",
        successPaymentText: "Děkujeme vám za objednávku, správce vás bude kontaktovat pro potvrzení objednávky.",
        failedPayment: "Platba byla neúspěšná",
        failedPaymentText: "Zopakujte prosím svou objednávku znovu",
        successResetPassword: "Heslo bylo úspěšně obnoveno",
        distributorRegistration: "Registrace distributora",
        professionalRegistration: "Registrace profesionála",
        infoAboutBonuses: "Informace o ID a bonusových bodech",
        contactDataDelivery: "Údaje pro doručení",
        promocode: "Propagační kód",
        certificateActivate: "Certifikát je aktivován",
        errorCertificate: "Neplatný certifikát",
        answers: "Odpovědi",
        failLogin: "Chyba autorizace",
        successAddFiles: "Soubor byl úspěšně uložen",
        aboutBrand: "O značce",
        scientificNews: "Vědecké novinky",
        programAmbassadors: "Program pro ambasadory",
        professionalClub: "Klub profesionálů",
        uploadFilesError: "Nahrát soubory",
        notActivated: "Účet není aktivován",
        userNotFound: "Žádný takový uživatel nebyl nalezen",
        successAddCosmetolog: "ID kosmetičky bylo úspěšně přidáno",
        successAddDistributor: "ID distributora úspěšně přidáno",
        messageResetPassword: "E-mail byl úspěšně odeslán",
        pickAddressAdd: "Přidat adresu příjemce",
        successRestorePassword: "Heslo úspěšně obnoveno",
        validation_error: "Zadané údaje jsou neplatné.",
        errorPromocode: "Neplatný promo kód",
        promocodeActivate: "Promo kód byl aktivován",
        searchWebsite: "Vyhledávání na webu",
        discountWithoutAgent: "Byla vám udělena jednorázová sleva 5 %",
        discountAgent: "Byla vám poskytnuta sleva 25 % podle zadaného profesního ID:",
        successRegisterAgent: "Jako profesionálnímu klientovi vám bude zaslán potvrzovací dopis.",
        emailIsUsed: "Tento e-mail je již obsazen",
        phoneIsUsed: "Klient s tímto telefonním číslem existuje",
        approvePayment: "Zaplaťte za",
        registrationUser: "Registraci",
        signSeminar: "Přihlaste se na seminář",
        signSeminarName: "Příjmení, jméno*.",
        signSeminarLocale: " Školení se koná v Kyjevě ve formátu ONLINE",
        signSeminarAddress: "na adrese",
        signSeminarFeedback: "Manažer vás bude brzy kontaktovat.",
        seminarSeries: "Série",
        selectionsSeminars: "Výběr seminářů",
        seminarSearch: "Vyhledávání podle tématu",
        seminarDate: "Datum semináře / Výběr období",
        seminarType: "Typ semináře",
        seminar: "Seminář",
        speaker: "Přednášející",
        seminarOnline: "Online - záznam",
        loginSeminar: "Přihlaste se prosím",
        signInSeminar: "pro přihlášení na seminář",
        resultsNotFound: "Nebyly nalezeny žádné výsledky",
        currentSchedule: "Aktuální rozvrh",
        allFiltersEmpty: "Nebyl zadán žádný filtr",
        profileBonuses: "Bonusy",
        passwordSuccessfullyChanged: "Heslo bylo úspěšně změněno!",
        popupUsingDetail: "Informace o používání průkazu Professional ID",
        popupDiscountDetail: "Další informace o slevě",
        deliveries: {
            accordingToTheCarrierTariffs: "Podle sazeb dopravce",
        },
        vacancies: "Volná místa",
        allAboutTheProduct: "Vše o produktu",
        warehouse: "Sklad",
        reviewsAndQuestions: "Recenze a dotazy",
        questionsAndAnswers: "Otázky a odpovědi",
        thankYouForContactingUsAManagerWillContactYouSoon:
            "Děkujeme, že jste nás kontaktovali! Brzy vás bude kontaktovat manažer",
        somethingWentWrongTryAgainLater: "Něco se pokazilo, zkuste to později",
        youMustAgreeToThePrivacyPolicy: "Musíte souhlasit se zásadami ochrany osobních údajů",
        fillInAllRequiredFields: "Vyplňte všechna povinná pole",
        filesAreRequired: "Soubory jsou povinné",
        agreeToThePrivacyPolicy: "Souhlasíte se zásadami ochrany osobních údajů",
        thisProductHasBeenMovedToANewSeriesAndCannotBePurchased:
            "Tento produkt byl přesunut do nové řady a nelze jej zakoupit",
        minimumNumberOfCharacters: "Minimální počet znaků",
        maximumNumberOfCharacters: "Maximální počet znaků",
        patientRegistration: "Registrace pacienta",
        registrationIsSuccessful: "Registrace proběhla úspěšně",
        thankYouForYourOrder: "Děkujeme za vaši objednávku!",
        afterPaymentIsConfirmedYouWillBeGivenAccessToTheTrainingInYourPersonalAccountInTheSectionMyOnlineTrainingPlatform:
            "Po potvrzení platby získáte přístup ke školení ve svém osobním účtu v sekci - Moje online školicí platforma.",
        expectConfirmationFromTheManagerAndPaymentDetails: "Očekávejte potvrzení od manažera a platební údaje",
        kyiv: "Kyjev",
        available: "k dispozici na",
        cosmetologist: "kosmetička",
        webinarWasSuccessfullyAddedToYourCart: "Webinář byl úspěšně přidán do košíku",
        indicateTheCorrectAnswer: "Uveďte správnou odpověď",
        pleaseSelectOneOrMoreCorrectAnswers: "Vyberte jednu nebo více správných odpovědí",
        writeAnAnswerToTheQuestion: "Napište odpověď na otázku",
        noDataFound: "Nebyly nalezeny žádné údaje",
        sortTypes: {
            priceAsc: "navýšení ceny",
            priceDesc: "klesající ceny",
            bestsellers: "propagační",
            newest: "nejprodávanější",
            withPromotions: "nové předměty",
        },
        certificateProblem: "Máte problém s vyplněním formuláře? <br> Popište svůj problém níže a my vás budeme kontaktovat",
        discountSystem: "Věrnostní systém",
        showDiscountInfoPopupText: "Osobní odstupňovaná sleva 5, 10, 12 % – stálý věrnostní systém pro partnery Renew!<br/><br/>Sleva je poskytována na následující měsíc v závislosti na výši objednávek v aktuálním měsíci.<br/><br/>- Při objednání ve výši 7 000 Kč – sleva 5%<br/>- Při objednání ve výši 10 000 Kč – sleva 10%<br/>- Při objednání ve výši 15 000 Kč – sleva 12%<br/><br/>Všechny výrobky značky Renew se započítávají do celkové částky objednávky.",
        PROFESSIONAL: "PROFESSIONAL",
        PROFESSIONAL_HEADER: "PROFESSIONAL",
        PROFILE: "PROFIL",
        selectBeautySalonStudio: "Salon krásy/studio",
        selectNetworkSalon: "Síťový salon",
        selectBeautyClinic: "Klinika krásy",
        selectNetworkClinic: "Síťová klinika",
        selectOnlineStore: "Internetový obchod",
        selectPhysicalStore: "Fyzický obchod",
        selectDealer: "Dealer",
        selectMasseur: "Masér",
        selectDoctor: "Lékař",
        selectDermatologist: "Dermatolog",
        selectAestheticMedicineDoctor: "Lékař estetické medicíny",
        selectPrivatePracticeCosmetologist: "Soukromá praxe - kosmetička",
        selectCosmetologistInjectionNurse: "Kosmetička-injekční sestra",
        zipCode: 'PSČ',
        promocodeDiscount: "Sleva promokódu",
        activatePromocode: "Aktivovat promokód",
        enterPromocode: "Zadejte promokód",
        promocodeSuccessActivated: "Promo kód byl úspěšně aktivován",
        all_care: "Veškeré péče: problémová kůže, čištění, kuperoza, anti-age terapie",
        cleaning: "Čištění",
        problem_skin: "Problémová kůže",
        massage: "Masáž",
        injection_procedures: "Injekční procedury",
        dermatology: "Dermatologie",
        anti_aging_therapy: "Anti-age terapie",
        peelings: "Peelingy",
        redemption: "Vykup",
        showcase_realization: "Realizace vitríny",
        spilling: "Rozlévání",
        online_store: "Internetový obchod",
        community_group: "Komunita, skupina",
        official_skin_on_retailer: "Oficiální retailer SkinOn",
        no: "Ne",
        to_1: "Začínající specialista do 1 roku",
        from_1_to_5: "Praktikující specialista 1-5 let",
        from_5_to_10: "5-10 let",
        from_10_to_20: "10-20 let",
        from_20_to_30: "20-30 let",
        more_than_30: "Více než 30 let",
        typeOfProcedure: "Typy procedur",
        sellingCare: "Selling care",
        birthDate: "Datum narození",
        workExperience: "Pracovní zkušenosti",
        laser_cosmetology_and_hair_removal: "Laserová kosmetologie a epilace",
        user_data_text: "*Vyplněním všech polí v dotazníku obdržíte příjemný bonus od společnosti. <br/> Prosím, obraťte se na svého osobního manažera po vyplnění."
    },
    en: {
        me: "Me",
        workPosition: "Position",
        typeOfActivity: "Type of business",
        social: "Social media",
        otherRegistration: "Other variant",
        aboutTheCompany: "ABOUT THE COMPANY",
        cooperation: "COOPERATION",
        publicOffer: "Public offer",
        termsOfUse: "Terms of use",
        setTheRenewShortcutToYourPhonesHomeScreenForQuickAccessToTheSite:
            "Set the Renew shortcut to your phones home screen for quick access to the site",
        share: "Share",
        press: "Press",
        addToScreen: "Add to Screen",
        homeScreen: "Home screen",
        applicationIconWillAppearOnYourScreen:
            "Na obrazovce se objeví ikona aplikace, nyní si můžete kdykoli dopřát kosmetický nákup 😍 Užijte si používání 😊.",
        yourDiscount: "Your discount",
        signIn: "Sign in",
        byRegisteringYouAgreeTo: "By registering you agree to",
        privacyPolicy: "privacy policy",
        andTermsOfUse: "and terms of use",
        whenRegisteringYourDataMayBeTransferredToThirdParties:
            "When registering, your data may be transferred to third parties.",
        basketEmpty: "BASKET EMPTY",
        volume: "Volume",
        total: "Total",
        continueShopping: "Continue shopping",
        placeAnOrder: "Place an order",
        withThisProductBuy: "With this product buy",
        noProductsAvailable: "No products available",
        getMoreInformationAboutTheProduct: "Get more information about the product",
        theGoodsAreOutOfStock: "The goods are out of stock",
        quantity: "Quantity",
        pcs: "pcs",
        phoneConfirmation: "Phone confirmation",
        ourTrainingEventsAreAConcentrationOfInformationAndAnIncredibleAtmosphere:
            "Our training events are a concentration of information and an incredible atmosphere. In the near future, a manager will contact you manager will contact you to confirm your registration.",
        afterCheckingTheDocumentsYouWillBeGrantedAccessToTheProfessionalsPersonalAccount:
            "After checking the documents, you will be granted access to the professional's personal account.",
        expectAConfirmationLetterToYourEmailShortly: "Expect a confirmation letter to your email shortly.",
        quickOrder: "Quick order",
        privateIndividual: "Private individual",
        organization: "Organization",
        edrpouCode: "EDRPOU code",
        companyName: "Company name",
        enterTheFormOfOwnership: "Enter the form of ownership",
        yourCartIsEmpty: "Your cart is empty",
        documentType: "Document type",
        bonusesUsed: "Bonuses used",
        youHave: "You have",
        "onTheBonusAccountYouCanWriteOffYourBonusUahInFullOrInPartToThisOrder":
            "on the Bonus account. You can write off your bonus UAH in full or in part to this order.",
        drivingToThePatient: "Delivery to the patient",
        thereWillBeNoInvoiceWithProfessionalPricesAsWellAsProfessionalManuals:
            "there will be no invoice with professional prices, as well as professional manuals",
        price: "Price",
        withYourDiscount: "with your discount",
        distributor: "distributor",
        theProductHasBeenMovedTo: "The product has been moved to",
        theNewSeries: "the new series",
        goTo: "Go to",
        question: "Question",
        answer: "Answer",
        reviews: "Reviews",
        tannicAcidAndVitaminCComponentsOfLongevity: "TANNIC ACID AND VITAMIN C - COMPONENTS OF LONGEVITY",
        uniqueInItsProperties:
            "Unique in its properties, Tannic Acid - the first in the world to be integrated by Alphascience laboratory - into anti-aging products with the help of the antioxidant Vitamin C - a natural",
        theDoubleActionOfAntioxidants:
            "preservative. The double action of antioxidants protects the skin from diseases, bacteria and aggressive environmental influences.",
        selectASection: "Select a section",
        watchTheVideoBusinessCard: "Watch the video business card",
        thisTrainingIsAvailableOnlyForSpecialists: "This training is available only for specialists (cosmetologists).",
        cost: "Cost",
        thisTrainingIsAlreadyAvailable: "This training is already available",
        thereAreNoWebinars: "There are no webinars",
        webinars: "Webinars",
        movement: "Movement",
        expenditureInvoice: "Expenditure invoice",
        movingNumber: "Moving number",
        date: "Date",
        client: "Client",
        contactDetailsOfTheRecipient: "Contact details of the recipient",
        comment: "Comment",
        comments: "Comments",
        products: "Products",
        articleNo: "Article no.",
        productName: "Product name",
        discount: "Discount",
        amount: "Amount",
        title: "Title",
        myLearningPlatform: "My learning platform",
        myOrders: "My orders",
        movingToTheWarehouse: "Moving to the warehouse",
        expenditureInvoices: "Expenditure invoices",
        myWarehouse: "My warehouse",
        warehouseBalances: "Warehouse balances",
        salesFromTheWarehouse: "Sales from the warehouse",
        submitASalesReport: "Submit a sales report",
        movementOfGoods: "Movement of goods",
        sales: "sales",
        displacement: "displacement",
        view: "View",
        remnants: "Remnants",
        actualSales: "Actual sales",
        totalAmount: "Total amount",
        thereAreNoRecords: "There are no records",
        testResults: "Test results",
        selectedProducts: "Selected products",
        noSelectedProducts: "No selected products",
        save: "Save",
        edit: "Edit",
        cancel: "cancel",
        test: "test",
        amountToBePaid: "Amount to be paid",
        downloadTheManual: "download the manual",
        thereIsNoManual: "download the methodology",
        thisUserDoesNotHaveAnyOrders: "This user does not have any orders",
        yourDiscountThisMonth: "Your discount this month",
        salesForTheCurrentMonth: "Sales for the current month",
        debt: "Debt",
        distributorIdIsAUniquePersonalCodeThatConfirmsTheRegistrationOfABeautySpecialistWithTheCompany:
            "Distributor ID is a unique personal code that confirms the registration of a beauty specialist with the company",
        beauticianIdIsAUniquePersonalCodeThatConfirmsTheRegistrationOfABeautySpecialistInTheCompany:
            "Beautician ID is a unique personal code that confirms the registration of a beauty specialist in the company",
        readMore: "read more",
        theSiteIsUnderMaintenance: "The site is under maintenance",
        tryAgainLater: "Try again later",
        beforeDownloadingTheFileYouNeedToSelectACityFromTheListProvided:
            "Before downloading the file, you need to select a city from the list provided",
        innovations: "Innovations",
        training: "TRAINING",
        loyalty: "LOYALTY",
        productInTheNewSeries: "Product in the new series",
        inTheBasket: "In the basket",
        showAll: "Show all",
        tableOfSizes: "Table of sizes",
        learnMoreAboutTheProduct: "Learn more about the product",
        dataForDelivery: "Data for delivery",
        with: "with",
        patient: "patient",
        thePriceOfAPatient: "The price of a patient",
        forOrdersOfTwoItemsOrMoreOneSampleAsAGift: "For orders of two items or more - One Sample as a gift",
        showMore: "Show more",
        aboutUs: "About us",
        basket: "SHOPPING CART",
        thankYouForRegistering: "Thank you for registering!",
        selectAnAddress: "Select an address",
        name: "First name",
        send: "Send",
        time: "Time",
        returnToBasket: "Return to cart",
        codeFromSms: "Code from SMS*",
        buy: "Buy",
        ownershipCodes: "FOP, TOV, PT, PP, AT...",
        reportAvailability: "Report availability",
        askAQuestion: "Ask a question",
        allNews: "All news",
        announcement: "Announcement",
        detailedDescriptionOfTheTraining: "Detailed description of the training",
        purchaseTraining: "Purchase training",
        downloadTheDocument: "Download the document",
        calculateSales: "Calculate sales",
        createAReport: "Create a report",
        downloadSalesReport: "Download sales report",
        downloadMore: "Download more",
        writeAnAnswer: "Write an answer",
        returnToYourAccount: "Return to your account",
        termsOfParticipation: "Terms of participation",
        start: "Start",
        enterTheNumberOfBonuses: "Enter the number of bonuses",
        validationEmail: "Enter a valid email address",
        validationLengthPhone: "Maximum phone length :count",
        validationMax: "Maximum number of characters :count",
        validationMin: "Minimum number of characters :count",
        validationRequired: "Fill in this field",
        validationSameAs: "Passwords do not match!",
        failLoadFiles: "File upload failed",
        emailActivationProfile: "A confirmation letter will be sent to you, the Professional Client, by e-mail.",
        registrationDistributor: "Distributor registration",
        registrationProfessional: "Professional registration",
        lastName: "Last name",
        country: "Country",
        city: "City",
        maxFileLoading: "Select file / Max 5 MB",
        phone: "Phone number",
        work: "Work address, name",
        approveDistributorFile:
            "To confirm that you are a distributor, please attach the relevant documents: diplomas, certificates, etc.",
        approveProfessionalFile:
            "To confirm that you are a cosmetologist, please attach the relevant documents: a cosmetologist's diploma and an identity document.",
        takeCity: "Choose a city",
        takeBranch: "Choose a branch",
        takeCountry: "Choose a country",
        takeDepartment: "Select a branch",
        street: "Street",
        building: "House",
        numberBuilding: "House number",
        flat: "Apartment",
        numberFlat: "Apartment number",
        value: "Volume",
        article: "Article",
        errorCodeDistributor: "Invalid distributor code",
        errorCodeProfessional: "Invalid beautician code",
        successChangeCosmetolog: "Beautician ID has been successfully updated",
        count: "Quantity",
        priceWithDiscount: "Price with discount",
        priceWithoutDiscount: "Price without discount",
        sum: "Amount",
        emptyBasket: "Cart is empty",
        enterIdDistributor: "Enter distributor ID",
        enterIdProfessional: "Enter professional ID",
        sumOrder: "Order amount",
        discountDistributor: "Distributor discount",
        discountProfessional: "Professional discount",
        discountClient: "Customer discount",
        totalToPay: "Total to be paid",
        payByBonuses: "Pay with bonuses",
        makeOrder: "Place an order",
        contactData: "Contact information",
        typeDelivery: "Delivery method",
        free: "Free of charge",
        grn: "UAH",
        contactDataRecipient: "Contact details of the recipient",
        whoRecipient: "Who is the recipient of the order?",
        anotherAddress: "Select another address and recipient",
        passportRule: "Receiving an order from 5000₴ only with a passport (Law of 06.12.2019 No. 361-IX).",
        typePayment: "Payment method",
        distributorDocuments: "Distributor's documents",
        professionalDocuments: "Documents of a professional",
        additionalInfo: "Additional information",
        additionalInfoHint:
            "(here you can leave your wishes for samples or printed materials that you want to receive with your order)",
        yourOrder: "Your order",
        st: "pcs",
        delivery: "Delivery",
        iHaveCode: "I have a promo code",
        orderTwoProducts: "For orders of two items or more",
        samplePresent: "One Sample as a gift",
        notCallBack: "I don't need to call back.",
        editOrder: "Edit your order",
        approveOrder: "Confirm your order",
        completeOrder: "Checkout the order",
        showClientPrices: "Show prices for a client",
        showProfessionalPrices: "Show prices for a professional",
        downloadClientInvoice: "Download invoice for a patient",
        downloadProfessionalInvoice: "Download invoice for a professional",
        downloadPDF: "Download PDF",
        inStock: "In stock",
        outOfStock: "Out of stock",
        distributorPrice: "Distributor price",
        professionalPrice: "Professional price",
        clientPrice: "Customer price",
        addToCard: "Add to cart",
        fastBuy: "Quick order",
        buyWithThis: "With this product bought",
        sendFeedback: "Leave a review",
        sendQuestion: "Ask a question",
        yourMessage: "Your message",
        writeFeedback: "Write a review",
        feedback: "Comment",
        description: "Description",
        readAllDescription: "Read the whole description",
        recommendations: "Recommendations",
        readAllRecommendations: "Read all recommendations",
        resetFilters: "Reset filters",
        sort: "Sort by",
        sort_types: {
            price_asc: "price increase",
            price_desc: "falling price",
            bestsellers: "promotional",
            newest: "top sales",
            with_promotions: "new products",
        },
        filter: "Filter",
        loadMore: "View more",
        default: "By default",
        priceSort: "prices",
        bestSeller: "Bestsellers",
        news: "New items",
        followInstagram: "Subscribe to Instagram",
        clubRules: "Club rules",
        before: "before",
        after: "after",
        download: "Download",
        workSchedule: "Work schedule",
        deliveryAndPayment: "Delivery and payment",
        faq: "Frequently asked questions",
        profile: "Personal account",
        idDistributor: "Distributor ID",
        idProfessional: "ID of a cosmetologist",
        distributorData: "Distributor data",
        professionalData: "Professional data",
        userData: "Personal data",
        currentOrder: "Current order",
        historyOrder: "Order history",
        clubDistributor: "My beauticians with ID",
        clubProfessional: "My patients with ID",
        clubDistributors: "My cosmetologists with ID",
        clubProfessionals: "My patients with ID",
        favorite: "My wish list",
        logOut: "Exit",
        code: "Code",
        phoneShort: "Tel.",
        selfPickup: "Pickup and delivery",
        repeatOrder: "Repeat the order",
        numberOrder: "Order number",
        status: "Status",
        empty: "Empty",
        bonusesValue: "Your bonus account",
        bonuses: "bonuses",
        distributorBonuses: "Distributor bonuses",
        professionalBonuses: "Professional bonuses",
        infoAboutDistributorIdUsage: "Information on using the distributor ID",
        infoAboutProfessionalIdUsage: "Information about using the ID of a professional",
        nameClient: "Last name and ID",
        bonusesFromOrder: "Bonuses from orders",
        professional: "Professional",
        archiveAddresses: "Address archive",
        nameRecipient: "First name of the recipient",
        lastNameRecipient: "Recipient's last name",
        phoneRecipient: "Recipient's phone number",
        addAddress: "Add a new address",
        change: "Edit",
        add: "ADD",
        documents: "Documents / images",
        contacts: "Contact us",
        secondName: "Name and patronymic",
        patronymic: "Middle name",
        activateAnotherId: "Activate another ID",
        resultSearch: "Search results",
        anotherDivision: "Other sections",
        posts: "News",
        pageNotFoundDescription: "Page not found",
        backToHome: "To the main page",
        error500: "Server error",
        politica: "Privacy policy",
        search: "Search",
        defaultPrices: "Order with prices for the client",
        clientPricesPopup: "Order with prices for a patient",
        professionalPricesPopup: "Order with prices for a professional",
        buyOneClick: "Buy in 1 click",
        addNewAddress: "Adding a new address",
        pickAddress: "Select the address and recipient",
        restorePassword: "Change password",
        changePassword: "Change password",
        resetPassword: "Reset password",
        sendRequest: "Leave a request",
        enterNewId: "Enter a new ID",
        activate: "Activate",
        streetShort: "street",
        saveAddress: "Add address",
        clientDiscount: "Customer discount",
        totalSum: "Total to be paid",
        enterPhone: "Enter your phone number",
        weCall: "We will call you back to place your order",
        workAndAddress: "Salon name and address",
        enter: "Sign in",
        registration: "Sign up for an account",
        email: "email",
        password: "Password",
        rememberMe: "Remember me",
        forgotPassword: "Forgot your password?",
        coupon: "Coupon",
        repeatPassword: "Reset password",
        registerPolitica: "By registering you agree to the privacy policy",
        registerPolitica2: "During registration, your data may be transferred to third parties",
        registered: "Are you registered ?",
        enterToProfile: "Log in to your account",
        restInstructions:
            "Enter the email you used during registration. You will receive an email with further instructions",
        emailExemple: "Example@gmail.com",
        sendMail: "Send email",
        backToLogin: "Return to authorization",
        restoreInstructions: "Come up with a new password. It must contain at least 6 characters (A-z, 0-9)",
        newPassword: "New password",
        oldPassword: "Old password",
        confirmPassword: "Confirm password",
        repeatNewPassword: "Repeat password",
        authWithNewPassword: "Log in with new password",
        cancelRestore: "Cancel password change",
        successRegistration: "Thank you for registering",
        yourDistributorID: "Your distributor ID",
        yourProfessionalID: "Your professional ID",
        successRemoveFromFavorite: "Successfully removed from favorites",
        successAddToFavorites: "Added to favorites",
        successAddBasket: "Added to cart",
        allAboutProduct: "All about the product",
        composition: "Composition",
        recommendation: "Recommendations",
        feedbackAndQuestion: "Reviews and questions",
        home: "Home page",
        catalog: "Catalog",
        successFeedbackSend: "Review successfully sent",
        successCreateOrder: "Order successfully placed",
        successLogin: "Successfully authorized",
        succesFastBuyCreate: "Order successfully saved",
        noData: "No data available",
        successQuestionSend: "Question successfully sent",
        successAddNewAddress: "New address added",
        successDeleteAddress: "Address successfully deleted",
        successChangeAddress: "Address successfully changed",
        address: "Address",
        emailText: "Mail",
        phones: "Phone numbers",
        newAddress: "New address",
        anotherPerson: "Another person",
        successResetContact: "Contact information has been successfully updated",
        requestSuccessSend: "Application has been sent",
        successSendFeedback: "Feedback successfully sent",
        successResetData: "Data successfully updated",
        register: "Register now",
        moreDetailed: "Read more",
        waitingForCall: "Waiting for a call",
        successFastBuyCreate: "Quick order placed",
        successRegisterWithDiscount: "You have been given a 25% discount according to the professional ID you entered:",
        readAllSyllable: "Read the whole composition",
        hide: "Hide",
        successPayment: "Payment was successful",
        successPaymentText: "Thank you for your order, the administrator will contact you to confirm the order",
        failedPayment: "Payment was unsuccessful",
        failedPaymentText: "Please repeat your order again",
        successResetPassword: "Password successfully recovered",
        distributorRegistration: "Distributor registration",
        professionalRegistration: "Professional registration",
        infoAboutBonuses: "Information about ID and bonus points",
        contactDataDelivery: "Data for delivery",
        promocode: "Promo code",
        certificateActivate: "Certificate is activated",
        errorCertificate: "Invalid certificate",
        answers: "Answers",
        failLogin: "Authorization error",
        successAddFiles: "File was successfully saved",
        aboutBrand: "About the brand",
        scientificNews: "Scientific news",
        programAmbassadors: "Program for ambassadors",
        professionalClub: "Club of the professional",
        uploadFilesError: "Upload files",
        notActivated: "Account is not activated",
        userNotFound: "No such user was found",
        successAddCosmetolog: "Beautician ID successfully added",
        successAddDistributor: "Distributor ID successfully added",
        messageResetPassword: "Email successfully sent",
        pickAddressAdd: "Add recipient's address",
        successRestorePassword: "Password successfully recovered",
        validation_error: "The entered data is invalid.",
        errorPromocode: "Invalid promo code",
        promocodeActivate: "Promo code has been activated",
        searchWebsite: "Search on the site",
        discountWithoutAgent: "You have been granted a one-time 5% discount",
        discountAgent: "You have been granted a 25% discount according to the Professional ID you entered:",
        successRegisterAgent: "A confirmation letter will be sent to you as a Professional Client.",
        emailIsUsed: "This email is already busy",
        phoneIsUsed: "A client with this phone number exists",
        approvePayment: "Pay for",
        registrationUser: "Registration",
        signSeminar: "Sign up for the seminar",
        signSeminarName: "Last name, first name*.",
        signSeminarLocale: " The training takes place in the ONLINE format in Kyiv",
        signSeminarAddress: "at the address",
        signSeminarFeedback: "A manager will contact you shortly.",
        seminarSeries: "Series",
        selectionsSeminars: "Selection of seminars",
        seminarSearch: "Search by topic",
        seminarDate: "Date of the seminar / Selection of the period",
        seminarType: "Type of seminar",
        seminar: "Seminar",
        speaker: "Speaker",
        seminarOnline: "Online - Recording",
        loginSeminar: "Please log in",
        signInSeminar: "to sign up for a seminar",
        resultsNotFound: "No results found",
        currentSchedule: "Current schedule",
        allFiltersEmpty: "No filter specified",
        profileBonuses: "Bonuses",
        passwordSuccessfullyChanged: "Password successfully changed!",
        popupUsingDetail: "Information about using the Professional ID",
        popupDiscountDetail: "More information about the discount",
        deliveries: {
            accordingToTheCarrierTariffs: "At the carrier's rates",
        },
        vacancies: "Vacancies",
        allAboutTheProduct: "All about the product",
        warehouse: "Warehouse",
        reviewsAndQuestions: "Reviews and questions",
        questionsAndAnswers: "Questions and answers",
        thankYouForContactingUsAManagerWillContactYouSoon:
            "Thank you for contacting us! A manager will contact you soon",
        somethingWentWrongTryAgainLater: "Something went wrong, try again later",
        youMustAgreeToThePrivacyPolicy: "You must agree to the privacy policy",
        fillInAllRequiredFields: "Fill in all required fields",
        filesAreRequired: "Files are required",
        agreeToThePrivacyPolicy: "Agree to the privacy policy",
        thisProductHasBeenMovedToANewSeriesAndCannotBePurchased:
            "This product has been moved to a new series and cannot be purchased",
        minimumNumberOfCharacters: "Minimum number of characters",
        maximumNumberOfCharacters: "Maximum number of characters",
        patientRegistration: "Patient registration",
        registrationIsSuccessful: "Registration is successful",
        thankYouForYourOrder: "Thank you for your order!",
        afterPaymentIsConfirmedYouWillBeGivenAccessToTheTrainingInYourPersonalAccountInTheSectionMyOnlineTrainingPlatform:
            "After payment is confirmed, you will be given access to the training in your Personal Account, in the section - My online training platform",
        expectConfirmationFromTheManagerAndPaymentDetails: "Expect confirmation from the manager and payment details",
        kyiv: "Kyiv",
        available: "available",
        cosmetologist: "cosmetologist",
        webinarWasSuccessfullyAddedToYourCart: "Webinar was successfully added to your cart",
        indicateTheCorrectAnswer: "Indicate the correct answer",
        pleaseSelectOneOrMoreCorrectAnswers: "Please select one or more correct answers",
        writeAnAnswerToTheQuestion: "Write an answer to the question",
        noDataFound: "No data found",
        sortTypes: {
            priceAsc: "Price increase",
            priceDesc: "Falling prices",
            bestsellers: "Bestsellers",
            newest: "Newest",
            withPromotions: "With promotions",
        },
        certificateProblem: "Are you having trouble filling out the form? <br> Describe the problem below and we will contact you.",
        discountSystem: "Discount system",
        showDiscountInfoPopupText: "Personal step discount of 5, 10, 12% is a permanent loyalty system for Renew partners!<br/><br/>The discount is provided for the next month depending on the orders amount in the current month<br/><br/>- For orders amount over 7,000 Kč - 5% discount<br/>- For orders amount over 10 000 Kč - 10% discount<br/>- For orders amount over 15 000 Kč - 12% discount<br/><br/>The orders amount includes all Renew brand products.",
        PROFESSIONAL: "PROFESSIONAL",
        PROFESSIONAL_HEADER: "PROFESSIONAL",
        PROFILE: "PROFILE",
        selectBeautySalonStudio: "Beauty salon/studio",
        selectNetworkSalon: "Network salon",
        selectBeautyClinic: "Beauty clinic",
        selectNetworkClinic: "Network clinic",
        selectOnlineStore: "Online store",
        selectPhysicalStore: "Physical store",
        selectDealer: "Dealer",
        selectMasseur: "Masseur",
        selectDoctor: "Doctor",
        selectDermatologist: "Dermatologist",
        selectAestheticMedicineDoctor: "Aesthetic medicine doctor",
        selectPrivatePracticeCosmetologist: "Private practice - cosmetologist",
        selectCosmetologistInjectionNurse: "Cosmetologist-Injection nurse",
        zipCode: 'ZIP code',
        promocodeDiscount: "Promocode discount",
        activatePromocode: "Activate promocode",
        enterPromocode: "Enter promocode",
        promocodeSuccessActivated: "Promocode successfully activated",
        all_care: "All care: problem skin, cleanings, couperose, anti-aging therapy",
        cleaning: "Cleanings",
        problem_skin: "Problem skin",
        massage: "Massage",
        injection_procedures: "Injection procedures",
        dermatology: "Dermatology",
        anti_aging_therapy: "Anti-aging therapy",
        peelings: "Peelings",
        redemption: "Redemption",
        showcase_realization: "Showcase realization",
        spilling: "Spilling",
        online_store: "Online store",
        community_group: "Community group",
        official_skin_on_retailer: "Official SkinOn retailer",
        no: "No",
        to_1: "Starting specialist up to 1 year",
        from_1_to_5: "Practicing specialist 1-5 years",
        from_5_to_10: "5-10 years",
        from_10_to_20: "10-20 years",
        from_20_to_30: "20-30 years",
        more_than_30: "More than 30 years",
        typeOfProcedure: "Type of procedure",
        sellingCare: "Selling care",
        birthDate: "Date of birth",
        workExperience: "Work experience",
        laser_cosmetology_and_hair_removal: "Laser cosmetology and hair removal",
        user_data_text: "*By filling out all fields in the form, you will receive a nice bonus from the company. <br/> Please contact your personal manager after completing the form."
    },
    ru: {
        me: "Я",
        typeOfActivity: "Род деятельности",
        workPosition: "Должность",
        social: "Социальные сети",
        otherRegistration: "Другой вариант",
        aboutTheCompany: "О КОМПАНИИ",
        cooperation: "СОТРУДНИЧЕСТВО",
        publicOffer: "Публичная оферта",
        termsOfUse: "Условия использования",
        setTheRenewShortcutToYourPhonesHomeScreenForQuickAccessToTheSite:
            "Установите ярлык Renew на главный экран вашего телефона для быстрого доступа к сайту",
        share: "Поделиться",
        press: "Нажмите",
        addToScreen: "Добавить на экран",
        homeScreen: "Главный экран",
        applicationIconWillAppearOnYourScreen:
            "На обратном пути вы можете воспользоваться этим аппликатором, но не забудьте доправить его до косметического накопления 😍 Уложите его в пакет 😊.",
        yourDiscount: "Ваша скидка",
        signIn: "Войти",
        byRegisteringYouAgreeTo: "Регистрируясь, вы соглашаетесь с",
        privacyPolicy: "политикой конфиденциальности",
        andTermsOfUse: "и условиями использования",
        whenRegisteringYourDataMayBeTransferredToThirdParties:
            "При регистрации ваши данные могут быть переданы третьим лицам.",
        basketEmpty: "КОРЗИНА ПУСТА",
        volume: "Объем",
        total: "Всего",
        continueShopping: "Продолжить покупки",
        placeAnOrder: "Оформить заказ",
        withThisProductBuy: "С этим товаром покупают",
        noProductsAvailable: "Нет продуктов в наличии",
        getMoreInformationAboutTheProduct: "Получить дополнительную информацию о товаре",
        theGoodsAreOutOfStock: "Товара нет в наличии",
        quantity: "Количество",
        pcs: "шт",
        phoneConfirmation: "Телефонное подтверждение",
        ourTrainingEventsAreAConcentrationOfInformationAndAnIncredibleAtmosphere:
            "Наши обучающие мероприятия - это концентрация информации и невероятная атмосфера. В ближайшее время с вами свяжется менеджер для подтверждения регистрации.",
        afterCheckingTheDocumentsYouWillBeGrantedAccessToTheProfessionalsPersonalAccount:
            "После проверки документов вам будет предоставлен доступ к личному кабинету специалиста.",
        expectAConfirmationLetterToYourEmailShortly:
            "В ближайшее время ожидайте письмо с подтверждением на вашу электронную почту.",
        quickOrder: "Быстрый заказ",
        privateIndividual: "Частное лицо",
        organization: "Организация",
        edrpouCode: "Код ЕДРПОУ",
        companyName: "Название компании",
        enterTheFormOfOwnership: "Укажите форму собственности",
        yourCartIsEmpty: "Ваша корзина пуста",
        documentType: "Тип документа",
        bonusesUsed: "Использованные бонусы",
        youHave: "У вас есть",
        "onTheBonusAccountYouCanWriteOffYourBonusUahInFullOrInPartToThisOrder":
            "на Бонусном счете. Вы можете списать свои бонусные гривны полностью или частично в этот заказ.",
        drivingToThePatient: "ОТПРАВКА ПАЦИЕНТУ",
        thereWillBeNoInvoiceWithProfessionalPricesAsWellAsProfessionalManuals:
            "Отправка без счета с указанной закупочной стоимостью профессионала; без печатной продукции",
        price: "Цена",
        withYourDiscount: "с вашей скидкой",
        distributor: "дистрибьютор",
        theProductHasBeenMovedTo: "Продукт был перемещен в",
        theNewSeries: "новая серия",
        goTo: "Перейти к",
        question: "Вопрос",
        answer: "Ответить",
        reviews: "Отзывы",
        tannicAcidAndVitaminCComponentsOfLongevity: "ДУБИЛЬНАЯ КИСЛОТА И ВИТАМИН С - КОМПОНЕНТЫ ДОЛГОЛЕТИЯ",
        uniqueInItsProperties:
            "Уникальная по своим свойствам дубильная кислота - первая в мире, интегрированная лабораторией Alphascience - в антивозрастные продукты с помощью антиоксиданта витамина С - природного",
        theDoubleActionOfAntioxidants:
            "консерванта. Двойное действие антиоксидантов защищает кожу от болезней, бактерий и агрессивного воздействия окружающей среды.",
        selectASection: "Выберите раздел",
        watchTheVideoBusinessCard: "Посмотреть видео визитную карточку",
        thisTrainingIsAvailableOnlyForSpecialists: "Данный тренинг доступен только для специалистов (косметологов).",
        cost: "Стоимость",
        thisTrainingIsAlreadyAvailable: "Этот курс уже доступен",
        thereAreNoWebinars: "Вебинары не проводятся",
        webinars: "Вебинары",
        movement: "Движение",
        expenditureInvoice: "Счет-фактура на расходы",
        movingNumber: "Номер перемещения",
        date: "Дата",
        client: "Клиент",
        contactDetailsOfTheRecipient: "Контактная информация получателя",
        comment: "Комментарий",
        comments: "Комментарии",
        products: "Продукция",
        articleNo: "Артикул",
        productName: "Название продукта",
        discount: "Скидка",
        amount: "Сумма",
        title: "Название",
        myLearningPlatform: "Моя учебная платформа",
        myOrders: "Мои заказы",
        movingToTheWarehouse: "Переезд на склад",
        expenditureInvoices: "Расходные накладные",
        myWarehouse: "Мой склад",
        warehouseBalances: "Остатки на складе",
        salesFromTheWarehouse: "Продажи со склада",
        submitASalesReport: "Отправить отчет о продажах",
        movementOfGoods: "Движение товаров",
        sales: "продажи",
        displacement: "перемещение",
        view: "Просмотреть",
        remnants: "Остатки",
        actualSales: "Фактические продажи",
        totalAmount: "Общая сумма",
        thereAreNoRecords: "Нет записей",
        testResults: "Результаты тестирования",
        selectedProducts: "Отобранные продукты",
        noSelectedProducts: "Нет выбранных товаров",
        save: "Сохранить",
        edit: "Редактировать",
        cancel: "отменить",
        test: "тест",
        amountToBePaid: "Сумма к оплате",
        downloadTheManual: "скачать руководство",
        thereIsNoManual: "скачать методику",
        thisUserDoesNotHaveAnyOrders: "У этого пользователя нет заказов",
        yourDiscountThisMonth: "Ваша скидка в этом месяце",
        salesForTheCurrentMonth: "Продажи за текущий месяц",
        debt: "Задолженность",
        distributorIdIsAUniquePersonalCodeThatConfirmsTheRegistrationOfABeautySpecialistWithTheCompany:
            "ID дистрибьютора - уникальный персональный код, подтверждающий регистрацию специалиста по красоте в компании",
        beauticianIdIsAUniquePersonalCodeThatConfirmsTheRegistrationOfABeautySpecialistInTheCompany:
            "ID косметолога - уникальный персональный код, подтверждающий регистрацию специалиста по красоте в компании",
        readMore: "читать далее",
        theSiteIsUnderMaintenance: "Сайт находится на техническом обслуживании",
        tryAgainLater: "Повторите попытку позже",
        beforeDownloadingTheFileYouNeedToSelectACityFromTheListProvided:
            "Перед скачиванием файла необходимо выбрать город из предложенного списка",
        innovations: "Инновации",
        training: "ОБУЧЕНИЕ",
        loyalty: "ЛОЯЛЬНОСТЬ",
        productInTheNewSeries: "Продукт из новой серии",
        inTheBasket: "В корзину",
        showAll: "Показать все",
        tableOfSizes: "Таблица размеров",
        learnMoreAboutTheProduct: "Узнать больше о продукте",
        dataForDelivery: "Данные для доставки",
        with: "с",
        patient: "пациент",
        thePriceOfAPatient: "Стоимость пациента",
        forOrdersOfTwoItemsOrMoreOneSampleAsAGift: "При заказе двух и более предметов - один образец в подарок",
        showMore: "Показать больше",
        aboutUs: "О нас",
        basket: "КОРЗИНА",
        thankYouForRegistering: "Спасибо за регистрацию!",
        selectAnAddress: "Выберите адрес",
        name: "Имя",
        send: "Отправить",
        time: "Время",
        returnToBasket: "Вернуться в корзину",
        codeFromSms: "Код из SMS*",
        buy: "Купить",
        ownershipCodes: "ФОП, ТОВ, ПТ, ПП, АТ...",
        reportAvailability: "Сообщить о наличии",
        askAQuestion: "Задать вопрос",
        allNews: "Все новости",
        announcement: "Объявление",
        detailedDescriptionOfTheTraining: "Подробное описание тренинга",
        purchaseTraining: "Приобрести тренинг",
        downloadTheDocument: "Скачать документ",
        calculateSales: "Рассчитать продажи",
        createAReport: "Создать отчет",
        downloadSalesReport: "Скачать отчет о продажах",
        downloadMore: "Скачать больше",
        writeAnAnswer: "Написать ответ",
        returnToYourAccount: "Вернуться в свой аккаунт",
        termsOfParticipation: "Условия участия",
        start: "Начать",
        enterTheNumberOfBonuses: "Введите количество бонусов",
        validationEmail: "Введите действительный адрес электронной почты",
        validationLengthPhone: "Максимальная длина телефона :count",
        validationMax: "Максимальное количество символов :count",
        validationMin: "Минимальное количество символов :count",
        validationRequired: "Заполните это поле",
        validationSameAs: "Пароли не совпадают!",
        failLoadFiles: "Загрузка файла не удалась",
        emailActivationProfile:
            "Подтверждающее письмо будет отправлено вам, Профессиональному клиенту, по электронной почте.",
        registrationDistributor: "Регистрация дистрибьютора",
        registrationProfessional: "Регистрация специалиста",
        lastName: "Фамилия",
        country: "Страна",
        city: "Город",
        maxFileLoading: "Выберите файл / Не более 5 МБ",
        phone: "Номер телефона",
        work: "Рабочий адрес, название",
        approveDistributorFile:
            "Чтобы подтвердить, что вы являетесь дистрибьютором, пожалуйста, приложите соответствующие документы: дипломы, сертификаты и т.д.",
        approveProfessionalFile:
            "Чтобы подтвердить, что вы являетесь косметологом, приложите соответствующие документы: диплом косметолога и документ, удостоверяющий личность.",
        takeCity: "Выберите город",
        takeBranch: "Выберите отделения",
        takeCountry: "Выберите страну",
        takeDepartment: "Выберите филиал",
        street: "Улица",
        building: "Дом",
        numberBuilding: "Номер дома",
        flat: "Квартира",
        numberFlat: "Номер квартиры",
        value: "Объем",
        article: "Артикул",
        errorCodeDistributor: "Неверный код дистрибьютора",
        errorCodeProfessional: "Неверный код косметолога",
        successChangeCosmetolog: "ID косметолога был успешно обновлен",
        count: "Количество",
        priceWithDiscount: "Цена со скидкой",
        priceWithoutDiscount: "Цена без скидки",
        sum: "Сумма",
        emptyBasket: "Корзина пуста",
        enterIdDistributor: "Введите ID дистрибьютора",
        enterIdProfessional: "Введите ID специалиста",
        sumOrder: "Сумма заказа",
        discountDistributor: "Дистрибьюторская скидка",
        discountProfessional: "Скидка профессионала",
        discountClient: "Скидка клиента",
        totalToPay: "Итог к оплате",
        payByBonuses: "Оплатить бонусами",
        makeOrder: "Оформить заказ",
        contactData: "Контактная информация",
        typeDelivery: "Способ доставки",
        free: "Бесплатно",
        grn: "UAH",
        contactDataRecipient: "Контактная информация получателя",
        whoRecipient: "Кто является получателем заказа?",
        anotherAddress: "Выберите другой адрес и получателя",
        passportRule: "Получение заказа от 5000₴ только при наличии паспорта (Закон от 06.12.2019 № 361-IX).",
        typePayment: "Способ оплаты",
        distributorDocuments: "Документы дистрибьютора",
        professionalDocuments: "Документы специалиста",
        additionalInfo: "Дополнительная информация",
        additionalInfoHint:
            "(здесь вы можете оставить свои пожелания относительно пробников или печатных материалов, которые вы хотите получить к заказу)",
        yourOrder: "Ваш заказ",
        st: "шт",
        delivery: "Доставка",
        iHaveCode: "У меня есть промо-код",
        orderTwoProducts: "При заказе двух и более товаров",
        samplePresent: "Один образец в подарок",
        notCallBack: "Мне не нужно перезванивать.",
        editOrder: "Редактировать заказ",
        approveOrder: "Подтвердить заказ",
        completeOrder: "Оформить заказ",
        showClientPrices: "Показать цены для клиента",
        showProfessionalPrices: "Показать цены для специалиста",
        downloadClientInvoice: "Скачать накладную для пациента",
        downloadProfessionalInvoice: "Скачать накладную для специалиста",
        downloadPDF: "Скачать PDF",
        inStock: "В наличии",
        outOfStock: "Нет в наличии",
        distributorPrice: "Цена дистрибьютора",
        professionalPrice: "Цена профессионала",
        clientPrice: "Цена для клиентов",
        addToCard: "Добавить в корзину",
        fastBuy: "Быстрый заказ",
        buyWithThis: "С этим товаром покупают",
        sendFeedback: "Оставить отзыв",
        sendQuestion: "Задать вопрос",
        yourMessage: "Ваше сообщение",
        writeFeedback: "Написать отзыв",
        feedback: "Комментарий",
        description: "Описание",
        readAllDescription: "Прочитать полное описание",
        recommendations: "Рекомендации",
        readAllRecommendations: "Прочитать все рекомендации",
        resetFilters: "Сбросить фильтры",
        sort: "Сортировать по",
        sort_types: {
            price_asc: "рост цен",
            price_desc: "падение цены",
            bestsellers: "промо",
            newest: "топ продаж",
            with_promotions: "новые продукты",
        },
        filter: "Фильтр",
        loadMore: "Смотреть больше",
        default: "По умолчанию",
        priceSort: "цены",
        bestSeller: "Бестселлеры",
        news: "Новые товары",
        followInstagram: "Подписаться на Instagram",
        clubRules: "Правила клуба",
        before: "до",
        after: "после",
        download: "Скачать",
        workSchedule: "График работы",
        deliveryAndPayment: "Доставка и оплата",
        faq: "Часто задаваемые вопросы",
        profile: "Личный кабинет",
        idDistributor: "ID дистрибьютора",
        idProfessional: "ID косметолога",
        distributorData: "Данные дистрибьютора",
        professionalData: "Данные специалиста",
        userData: "Персональные данные",
        currentOrder: "Текущий заказ",
        historyOrder: "История заказов",
        clubDistributor: "Мои косметологи с ID",
        clubProfessional: "Мои пациенты с удостоверением личности",
        clubDistributors: "Мои косметологи с ID",
        clubProfessionals: "Мои пациенты с ID",
        favorite: "Мой список желаний",
        logOut: "Выход",
        code: "Код",
        phoneShort: "Тел.",
        selfPickup: "Забрать и доставить",
        repeatOrder: "Повторить заказ",
        numberOrder: "Номер заказа",
        status: "Статус",
        empty: "Пустой",
        bonusesValue: "Ваш бонусный счет",
        bonuses: "бонусы",
        distributorBonuses: "Бонусы дистрибьюторов",
        professionalBonuses: "Профессиональные бонусы",
        infoAboutDistributorIdUsage: "Информация об использовании идентификатора дистрибьютора",
        infoAboutProfessionalIdUsage: "Информация об использовании ID профессионала",
        nameClient: "Фамилия и ID",
        bonusesFromOrder: "Бонусы от заказов",
        professional: "Профессионал",
        archiveAddresses: "Архив адресов",
        nameRecipient: "Имя получателя",
        lastNameRecipient: "Фамилия получателя",
        phoneRecipient: "Номер телефона получателя",
        addAddress: "Добавить новый адрес",
        change: "Редактировать",
        add: "ДОБАВИТЬ",
        documents: "Документы / изображения",
        contacts: "Контакты",
        secondName: "Имя и фамилия",
        patronymic: "Отчество",
        activateAnotherId: "Активировать другой идентификатор",
        resultSearch: "Результаты поиска",
        anotherDivision: "Другие разделы",
        posts: "Новости",
        pageNotFoundDescription: "Страница не найдена",
        backToHome: "На главную страницу",
        error500: "Ошибка сервера",
        politica: "Политика конфиденциальности",
        search: "Поиск",
        defaultPrices: "Заказ с ценами для клиента",
        clientPricesPopup: "Заказ с ценами для пациента",
        professionalPricesPopup: "Заказ с ценами для профессионала",
        buyOneClick: "Купить в 1 клик",
        addNewAddress: "Добавление нового адреса",
        pickAddress: "Выберите адрес и получателя",
        restorePassword: "Изменить пароль",
        changePassword: "Изменить пароль",
        resetPassword: "Сбросить пароль",
        sendRequest: "Оставить заявку",
        enterNewId: "Введите новый идентификатор",
        activate: "Активировать",
        streetShort: "улица",
        saveAddress: "Добавить адрес",
        clientDiscount: "Скидка клиента",
        totalSum: "Общая сумма к оплате",
        enterPhone: "Введите номер телефона",
        weCall: "Мы перезвоним вам, чтобы оформить заказ",
        workAndAddress: "Название и адрес салона",
        enter: "Войти",
        registration: "Регистрация",
        email: "Электронная почта",
        password: "Пароль",
        rememberMe: "Запомнить меня",
        forgotPassword: "Забыли пароль?",
        coupon: "Купон",
        repeatPassword: "Повторите пароль",
        registerPolitica: "Регистрируясь, вы соглашаетесь с политикой конфиденциальности",
        registerPolitica2: "Во время регистрации ваши данные могут быть переданы третьим лицам",
        registered: "Вы зарегистрированы?",
        enterToProfile: "Войдите в свой аккаунт",
        restInstructions:
            "Введите адрес электронной почты, который вы использовали при регистрации. Вы получите письмо с дальнейшими инструкциями",
        emailExemple: "Example@gmail.com",
        sendMail: "Отправить письмо",
        backToLogin: "Вернуться к авторизации",
        restoreInstructions: "Придумайте новый пароль. Он должен содержать не менее 6 символов (A-z, 0-9)",
        newPassword: "Новый пароль",
        oldPassword: "Старый пароль",
        confirmPassword: "Подтвердите пароль",
        repeatNewPassword: "Повторите пароль",
        authWithNewPassword: "Войти в систему с новым паролем",
        cancelRestore: "Отменить смену пароля",
        successRegistration: "Спасибо за регистрацию",
        yourDistributorID: "Ваш идентификатор дистрибьютора",
        yourProfessionalID: "Ваш профессиональный идентификатор",
        successRemoveFromFavorite: "Успешно удалено из избранного",
        successAddToFavorites: "Добавлено в избранное",
        successAddBasket: "Добавлено в корзину",
        allAboutProduct: "Все о продукте",
        composition: "Состав",
        recommendation: "Рекомендации",
        feedbackAndQuestion: "Отзывы и вопросы",
        home: "Главная страница",
        catalog: "Каталог",
        successFeedbackSend: "Отзыв успешно отправлен",
        successCreateOrder: "Заказ успешно размещен",
        successLogin: "Успешно авторизован",
        succesFastBuyCreate: "Заказ успешно сохранен",
        noData: "Нет данных",
        successQuestionSend: "Вопрос успешно отправлен",
        successAddNewAddress: "Добавлен новый адрес",
        successDeleteAddress: "Адрес успешно удален",
        successChangeAddress: "Адрес успешно изменен",
        address: "Адрес",
        emailText: "Почта",
        phones: "Номера телефонов",
        newAddress: "Новый адрес",
        anotherPerson: "Другой человек",
        successResetContact: "Контактная информация была успешно обновлена",
        requestSuccessSend: "Заявка отправлена",
        successSendFeedback: "Обратная связь успешно отправлена",
        successResetData: "Данные успешно обновлены",
        register: "Зарегистрируйтесь сейчас",
        moreDetailed: "Читать далее",
        waitingForCall: "Ожидание звонка",
        successFastBuyCreate: "Быстрый заказ оформлен",
        successRegisterWithDiscount:
            "Вам предоставлена скидка 25% в соответствии с введенным профессиональным идентификатором:",
        readAllSyllable: "Читать весь состав",
        hide: "Скрыть",
        successPayment: "Оплата прошла успешно",
        successPaymentText: "Спасибо за заказ, администратор свяжется с вами для подтверждения заказа",
        failedPayment: "Оплата прошла неудачно",
        failedPaymentText: "Пожалуйста, повторите заказ еще раз",
        successResetPassword: "Пароль успешно восстановлен",
        distributorRegistration: "Регистрация дистрибьютора",
        professionalRegistration: "Регистрация специалиста",
        infoAboutBonuses: "Информация о ID и бонусных баллах",
        contactDataDelivery: "Данные для доставки",
        promocode: "Промо-код",
        certificateActivate: "Сертификат активирован",
        errorCertificate: "Недействительный сертификат",
        answers: "Ответы",
        failLogin: "Ошибка авторизации",
        successAddFiles: "Файл успешно сохранен",
        aboutBrand: "О бренде",
        scientificNews: "Новости науки",
        programAmbassadors: "Программа для послов",
        professionalClub: "Клуб профессионалов",
        uploadFilesError: "Загрузить файлы",
        notActivated: "Аккаунт не активирован",
        userNotFound: "Такой пользователь не найден",
        successAddCosmetolog: "ID косметолога успешно добавлен",
        successAddDistributor: "ID дистрибьютора успешно добавлен",
        messageResetPassword: "Электронная почта успешно отправлена",
        pickAddressAdd: "Добавить адрес получателя",
        successRestorePassword: "Пароль успешно восстановлен",
        validation_error: "Введенные данные недействительны.",
        errorPromocode: "Недействительный промо-код",
        promocodeActivate: "Промо-код был активирован",
        searchWebsite: "Поиск на сайте",
        discountWithoutAgent: "Вам предоставлена единовременная скидка 5%",
        discountAgent: "Вам предоставлена скидка 25% в соответствии с введенным вами Профессиональным идентификатором:",
        successRegisterAgent: "Вам, как Профессиональному клиенту, будет отправлено письмо с подтверждением.",
        emailIsUsed: "Этот e-mail уже занят",
        phoneIsUsed: "Клиент с таким номером телефона уже существует",
        approvePayment: "Оплатить",
        registrationUser: "Регистрация",
        signSeminar: "Зарегистрироваться на семинар",
        signSeminarName: "Фамилия, имя*.",
        signSeminarLocale: " Обучение проходит в формате ONLINE в Киеве",
        signSeminarAddress: "по адресу",
        signSeminarFeedback: "Менеджер свяжется с вами в ближайшее время.",
        seminarSeries: "Серия",
        selectionsSeminars: "Подборка семинаров",
        seminarSearch: "Поиск по теме",
        seminarDate: "Дата проведения семинара / Выбор периода",
        seminarType: "Тип семинара",
        seminar: "Семинар",
        speaker: "Спикер",
        seminarOnline: "Онлайн - Запись",
        loginSeminar: "Пожалуйста, войдите в систему",
        signInSeminar: "чтобы записаться на семинар",
        resultsNotFound: "Результаты не найдены",
        currentSchedule: "Текущее расписание",
        allFiltersEmpty: "Фильтр не указан",
        profileBonuses: "Бонусы",
        passwordSuccessfullyChanged: "Пароль успешно изменен!",
        popupUsingDetail: "Информация об использовании Профессионального удостоверения",
        popupDiscountDetail: "Дополнительная информация о скидке",
        deliveries: {
            accordingToTheCarrierTariffs: "По тарифу перевозчика",
        },
        vacancies: "Вакансии",
        allAboutTheProduct: "Все о товаре",
        warehouse: "Склад",
        reviewsAndQuestions: "Отзывы и вопросы",
        questionsAndAnswers: "Вопросы и ответы",
        thankYouForContactingUsAManagerWillContactYouSoon:
            "Спасибо, что связались с нами! Менеджер свяжется с вами в ближайшее время",
        somethingWentWrongTryAgainLater: "Что-то пошло не так, повторите попытку позже",
        youMustAgreeToThePrivacyPolicy: "Вы должны согласиться с политикой конфиденциальности",
        fillInAllRequiredFields: "Заполните все необходимые поля",
        filesAreRequired: "Файлы обязательны",
        agreeToThePrivacyPolicy: "Согласен с политикой конфиденциальности",
        thisProductHasBeenMovedToANewSeriesAndCannotBePurchased:
            "Этот продукт был перемещен в новую серию и не может быть приобретен",
        minimumNumberOfCharacters: "Минимальное количество символов",
        maximumNumberOfCharacters: "Максимальное количество символов",
        patientRegistration: "Регистрация пациента",
        registrationIsSuccessful: "Регистрация успешна",
        thankYouForYourOrder: "Спасибо за ваш заказ!",
        afterPaymentIsConfirmedYouWillBeGivenAccessToTheTrainingInYourPersonalAccountInTheSectionMyOnlineTrainingPlatform:
            "После подтверждения оплаты вам будет предоставлен доступ к тренингу в вашем личном кабинете, в разделе - Моя платформа онлайн-тренингов",
        expectConfirmationFromTheManagerAndPaymentDetails: "Ожидайте подтверждения от менеджера и реквизиты для оплаты",
        kyiv: "Киев",
        available: "доступно",
        cosmetologist: "косметолог",
        webinarWasSuccessfullyAddedToYourCart: "Вебинар успешно добавлен в корзину",
        indicateTheCorrectAnswer: "Укажите правильный ответ",
        pleaseSelectOneOrMoreCorrectAnswers: "Пожалуйста, выберите один или несколько правильных ответов",
        writeAnAnswerToTheQuestion: "Напишите ответ на вопрос",
        noDataFound: "Данные не найдены",
        sortTypes: {
            priceAsc: "От дешевых к дорогим",
            priceDesc: "От дорогих к дешевым",
            bestsellers: "Бестселлеры",
            newest: "Самые новые",
            withPromotions: "С акциями",
        },
        certificateProblem: "Столкнулись с проблемой при заполнении формы? <br> Опишите проблему ниже, и мы с Вами свяжемся.",
        discountSystem: "Система лояльности",
        showDiscountInfoPopupText: "Персональная ступенчатая скидка 5,10,12% – постоянная система лояльности для партнеров Renew!<br/> <br/>Скидка предоставляется на следующий месяц в зависимости от суммы заказов в текущем месяце.<br/><br/>• При сумме заказов от 7 000 Kč – скидка 5%<br/>• При сумме заказов от 10 000 Kč – скидка 10%<br/>• При сумме заказов от 15 000 Kč – скидка 12% <br/> <br/> В сумму заказов входят все препараты торговой марки Renew",
        PROFESSIONAL: "PROFESSIONAL",
        PROFESSIONAL_HEADER: "PROFESSIONAL",
        PROFILE: "ПРОФИЛЬ",
        selectBeautySalonStudio: "Салон красоты/студия",
        selectNetworkSalon: "Сетевой салон",
        selectBeautyClinic: "Клиника красоты",
        selectNetworkClinic: "Сетевая клиника",
        selectOnlineStore: "Интернет-магазин",
        selectPhysicalStore: "Физический магазин",
        selectDealer: "Дилер",
        selectMasseur: "Массажист",
        selectDoctor: "Доктор",
        selectDermatologist: "Дерматолог",
        selectAestheticMedicineDoctor: "Доктор эстетической медицины",
        selectPrivatePracticeCosmetologist: "Частный кабинет - косметолог",
        selectCosmetologistInjectionNurse: "Косметолог-инъекционист",
        zipCode: 'Почтовый индекс',
        promocodeDiscount: "Скидка промокода",
        activatePromocode: "Активировать промокод",
        enterPromocode: "Введите промокод",
        promocodeSuccessActivated: "Промокод успешно активирован",
        all_care: "Все уходы: проблемная кожа, чистки, купероз, антивозрастная терапия",
        cleaning: "Чистки",
        problem_skin: "Проблемная кожа",
        massage: "Массаж",
        injection_procedures: "Инъекции",
        dermatology: "Дерматология",
        anti_aging_therapy: "Антивозрастная терапия",
        peelings: "Пилинги",
        redemption: "Под выкуп",
        showcase_realization: "Реализация витрины",
        spilling: "Распив",
        online_store: "Интернет-магазин",
        community_group: "Продажа через сообщество, группу",
        official_skin_on_retailer: "Официальный ритейлер SkinOn",
        no: "Не продаю дом. уход",
        to_1: "Начинающий специалист до 1 года",
        from_1_to_5: "Практикующий специалист 1-5 лет",
        from_5_to_10: "5-10 лет",
        from_10_to_20: "10-20 лет",
        from_20_to_30: "20-30 лет",
        more_than_30: "Более 30 лет",
        typeOfProcedure: "Виды процедур",
        sellingCare: "Продажа дом. ухода",
        birthDate: "Дата рождения",
        workExperience: "Стаж работы",
        laser_cosmetology_and_hair_removal: "Лазерная косметология и эпиляция",
        user_data_text: "*при заполнении всех полей в анкете, вы получите приятный бонус от компании. <br/> Пожалуйста, обратитесь к персональному менеджеру после заполнения."
    },
};
