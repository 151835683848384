import { mapGetters, mapMutations } from "vuex";

import { PopupName } from "@/store/modules/popups";

export default {
    name: "metodist-popup",
    computed: {
        ...mapGetters({
            metodistVideo: "popups/currentModalPayload",
            currentModal: "popups/currentModal",
        }),
        metodistPopup() {
            return this.currentModal === PopupName.metodistPopup
        },
    },
    methods: {
        ...mapMutations({
            changeMetodistPopup: "popups/CHANGE_METODIST_POPUP",
        }),
        closeMetodistPopup() {
            this.changeMetodistPopup({status: false});
        },
        handleVideoEnd() {
            this.closeMetodistPopup();
        },
    },
};
