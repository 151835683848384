import { isArray } from "lodash";

/*
const UserType = {
  Distributor: 'distributor',
  Professional: 'professional',
  Authorized_user: 'authorized_user',
  Unauthorized_user: 'unauthorized_user',
}
*/

/**
 * @param {User} user
 * @param {Array<UserType|UserTypes>|UserType|UserTypes} userTypes
 * @return {boolean}
 */
const checkUserType = (user, userTypes) => {
    if (!user) {
        return false;
    }

    if (!isArray(userTypes)) {
        userTypes = [userTypes];
    }

    return userTypes.includes(user.userType.name);
};

export default {
    checkUserType,
};
