// import mainInput from '../../atoms/main-input/index.vue'
import { mapActions, mapGetters, mapMutations } from "vuex";

import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";
import PhoneMaskInput from "vue-phone-mask-input";

export default {
    name: "notify-when-available",
    data() {
        return {
            payload: {
                name: "",
                phone: "",
                email: "",
            },
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            email: {
                required,
                maxLength: maxLength(120),
                email,
            },
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(120),
            },
            phone: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(14),
            },
        },
    },
    components: {
        PhoneMaskInput
    },
    computed: {
        ...mapGetters({
            loadingSingIn: "auth/loadingSingIn",
            notifyData: "popups/currentModalPayload",
            showNotifyLoader: "products/showNotifyLoader",
        }),
        nameErrors() {
            return this.computeErrors("payload", "name", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                minLength: { message: "validationMin", count: 2 },
            });
        },
        phoneErrors() {
            return this.computeErrors("payload", "phone", {
                required: "validationRequired",
                minLength: { message: "minimumNumberOfCharacters", count: 8 },
                maxLength: { message: "maximumNumberOfCharacters", count: 14 },
            });
        },
        emailErrors() {
            return this.computeErrors("payload", "email", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                email: "validationEmail",
            });
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        ...mapMutations({
            changeSeminarDetailsPopup: "popups/CHANGE_SEMINAR_DETAILS_POPUP",
        }),
        ...mapActions({
            fetchData: "products/SET_PRODUCT_PRODUCT_INFO",
        }),
        submit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            let obj = {
                name: this.payload.name,
                phone: this.payload.phone,
                email: this.payload.email,
                ...this.notifyData,
            };

            const showPopup = () => {
                this.changeSeminarDetailsPopup({status: true, payload: {
                    title: "",
                    detail: this.$t("thankYouForContactingUsAManagerWillContactYouSoon"),
                }});
            };

            this.fetchData(obj)
                .then(showPopup)
                .catch(showPopup);
        },
    },
};
