import { debounce } from "lodash";

export default {
    props: {
        label: {
            type: String,
            default: "",
        },
        progress: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "",
        },
        placeholderCapitalized: {
            type: Boolean,
            default: true,
        },
        itemValue: {
            type: String,
            default: "default",
        },
        itemTitle: {
            type: String,
            default: "name",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        list: {
            type: [Array, Object],
            default: null,
        },
        requestInterval: {
            type: Number,
            default: 700,
        },
        value: {
            type: Array,
            default: () => []
        },
        isValid: {
            type: Boolean,
            default: true,
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.processInputList();
    },
    data() {
        return {
            openSelect: false,
            selectedItems: [],
        };
    },
    methods: {
        changeSelect(item) {
            this.openSelect = true;
            if (this.checkIsItemsSelected(item)) {
                this.removeItemFromArray(item);
            } else {
                if (this.singleSelect) {
                    this.selectedItems = [item];
                } else {
                    this.addItemToArray(item);
                }
            }
            this.$emit("change-list", this.selectedItems);
            this.openSelect = true;
        },

        processInputList(){
            if (this.value.length !== null) {
                this.value.map(item => {
                    Object.values(this.list).find(listItem => {
                        listItem.title === item ? this.selectedItems.push(listItem) : void 0;
                    })
                })
            }
        },

        addItemToArray(item) {
            this.selectedItems.push(item);
        },

        removeItemFromArray(item) {
            this.selectedItems = this.selectedItems.filter(
                (el) => el[this.itemValue] !== item[this.itemValue]
            );
        },

        checkIsItemsSelected(item) {
            return this.selectedItems.includes(item);
        },

        changeShowTooltipList: debounce(function(status) {
            if (this.disabled) return
            this.openSelect = status;
        }, 100),

        handleClick() {
            if(this.openSelect) {
                this.openSelect = false;
            }
        }
    },
};
