import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import { $http } from "@/utils/https";
import type { Product } from "@/types/shop";

interface State {
    countryList: any;
    cityList: any;
}

const state: State = {
    countryList: null,
    cityList: null,
}

const getters = {
    countryList: (state: State) => state.countryList,
    cityList: (state: State) => state.cityList,
}

const actions = {
    GET_COUNTRY_LIST: async ({commit}: ActionContext<State, RootState>) => {
        commit('SET_COUNTRY_LIST', []);
        try {
            const response = await $http.get<{ data: Product }>(`v1/countries`);
            commit('SET_COUNTRY_LIST', response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
        }
    },
    GET_CITY_LIST: async ({commit}: ActionContext<State, RootState>, payload: {code: string, search?: string}) => {
        commit('SET_COUNTRY_CITY', null);
        let url = `v1/cities?country=${payload.code}`;
        if (!!payload.search) {
            url += `&q=${payload.search}`;
        }
        try {
            const response = await $http.get<{ data: Product }>(url);
            commit('SET_COUNTRY_CITY', response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
        }
    },
}

const mutations = {
    SET_COUNTRY_LIST(state: State, value: any) {
        state.countryList = value;
    },
    SET_COUNTRY_CITY(state: State, value: any) {
        state.cityList = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
