import {mapGetters, mapMutations} from "vuex";
import {$http} from '@/utils/https'
// import validation from "@/mixins/validation";
// import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
    name: 'certificateError',
    data() {
        return {
            sending: false,
            comment: ''
        }
    },
    computed: {
        ...mapGetters({
            currentModalPayload: "popups/currentModalPayload"
        }),
    },
    methods: {
        ...mapMutations({
            changeCertificateProblemPopup: "popups/CHANGE_CERTIFICATE_PROBLEM_POPUP"
        }),
        async submitForm() {
            this.sending = true;
            const payload = {
                "issue_comment": this.comment,
                ...this.currentModalPayload
            };
            try {
                const response = await $http.post('v1/auth/register/store-issue', payload);
                this.changeCertificateProblemPopup({status: false, payload: null})
                this.$toasted.success(response.data.message, { duration: 7500 });
            }
            catch (e) {
                this.$toasted.error(this.$t('somethingWentWrongTryAgainLater'));
                console.log(e);
            }
            finally {
                this.sending = false;
            }
        }
    }
}

