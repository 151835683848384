import { mapActions, mapGetters, mapMutations } from "vuex";
import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import validation from "@/mixins/validation";

export default {
    name: "login",

    data() {
        return {
            payload: {
                email: this.$route.query.email ? this.$route.query.email : "",
                password: "",
            },
            validationErrors: {},
            showPassword: false,
            inputRefs: [],
            rememberMe: false
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            email: {
                required,
                maxLength: maxLength(120),
                email,
            },
            password: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(120),
            },
        },
    },
    components: {},
    computed: {
        ...mapGetters({
            loadingSingIn: "auth/loadingSingIn",
        }),
        passwordErrors() {
            return this.computeErrors("payload", "password", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                minLength: { message: "validationMin", count: 8 },
            });
        },
        emailErrors() {
            return this.computeErrors("payload", "email", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                email: "validationEmail",
            });
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");

        if (!this.$route.query.email) this.fillSavedData();
    },
    methods: {
        ...mapMutations({
            changeRegistrationPopup: "popups/CHANGE_REGISTRATION_POPUP",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
            changeResetPopup: "popups/CHANGE_RESET_POPUP",
            changeRegisterAgentPopup: "popups/CHANGE_AGENT_REGISTER_POPUP",
        }),
        ...mapActions({
            login: "auth/GET_TOKEN",
            fetchUser: "profile/FETCH_USER_DATA",
            getFavorites: "favorites/GET_FAVORITES_LIST",
            fetchHeaderMenu: "setting/GET_HEADER_MENU",
        }),
        setItemRef(el) {
            if (el) {
                this.inputRefs.push(el);
            }
        },
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.login(this.payload).then(() => {
                    this.fetchUser().then(() => this.fetchHeaderMenu());
                    this.getFavorites();
                });

                if (this.rememberMe) {
                    localStorage.setItem('rememberedEmail', this.hashData(this.payload.email));
                    localStorage.setItem('rememberedPassword', this.hashData(this.payload.password));
                }
                else {
                    localStorage.removeItem('rememberedEmail');
                    localStorage.removeItem('rememberedPassword');
                }

                this.$toasted.success(this.$t("successLogin"));
                this.changeLoginPopup(false);

                this.$router.nav;
                // if (this.$route.name !== 'seminars' || this.$route.name !== 'buy-flow-basket') {
                //   this.$router.push({name: 'profile'}).then(() => {}).catch(() => {})
                // }
            } catch (error) {
                const message = error.data?.message;
                if (message) {
                    this.$toasted.error(message);
                }
            }
        },
        hashData(data) {
            return btoa(data);
        },
        fillSavedData() {
            const savedEmail = localStorage.getItem('rememberedEmail');
            const savedPassword = localStorage.getItem('rememberedPassword');

            if (savedEmail && savedPassword) {
                this.payload.email = atob(savedEmail);
                this.payload.password = atob(savedPassword);
                this.rememberMe = true;
            }
        },
    },
};
