import defaultImage from "@/assets/img/svg/default-image.svg";

/**
 *
 * @param {Product|BasketProduct} product
 * @param {ProductPrice|null} productPrice
 * @return {{query: {value}, name: string, params: {query, slug}}}
 */
export function routeToProduct(product, productPrice = null) {
    let value = productPrice?.value || product.productPrices?.data[0]?.value;

    return {
        name: "card",
        params: {
            slug: product.slug,
            query: value,
        },
        query: {
            value: value,
        },
    };
}

/**
 *
 * @param {Product|BasketProduct} product
 * @param {ProductPrice|null} productPrice
 * @return {string|null}
 */
export function resolveProductImage(product, productPrice = null) {
    return (
        productPrice?.image ||
        product?.select_type?.image ||
        product?.productPrices?.data[0]?.image ||
        product.image ||
        defaultImage
    );
}

/**
 * @param {ProductPrice} productPrice
 * @return {string}
 */
export function getProductPriceTitle(productPrice) {
    return productPrice.title || productPrice.value;
}
