import { mapGetters } from "vuex";

export default {
    name: "custom-breadcrumbs",
    data() {
        return {};
    },
    props: {
        list: {
            type: Array,
            default: null,
        },
    },
    computed: {
        ...mapGetters({
            translations: "setting/translations",
        }),
        sortedList() {
            let newArr = [];
            this.list.forEach(el => {
                if (Object.keys(el).length !== 0 && el.title !== undefined) {
                    newArr.push(el);
                }
            });
            return newArr;
        },
    },
    created() {},
};
