import { mapGetters } from "vuex";

export default {
    name: "seminar-info",
    computed: {
        ...mapGetters({
            seminarInfoContent: "popups/currentModalPayload",
        }),
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
};
