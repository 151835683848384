import { minLength, required } from "vuelidate/lib/validators";

import { mapActions, mapGetters, mapMutations } from "vuex";
import validation from "@/mixins/validation";

export default {
    name: "fast-buy",

    data() {
        return {
            payload: {
                phone: "",
            },
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            phone: {
                required,
                minLength: minLength(13),
            },
        },
    },
    computed: {
        ...mapGetters({
            product: "card/product",
            isLoading: "order/isLoading",
            fastBuyPopup: "popups/currentModalPayload",
        }),
        phoneErrors() {
            return this.computeErrors("payload", "phone", {
                required: "validationRequired",
                minLength: { message: "validationMin", count: 13 },
            });
        },
    },
    created() {
        document.getElementById("html").classList.add("hide");
    },
    methods: {
        ...mapActions({
            createOrder: "order/CREATE_ORDER",
        }),
        ...mapMutations({
            changeFastBuyPopup: "popups/CHANGE_FAST_BUY_POPUP",
        }),
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let products = [];
                products.push({
                    id: this.fastBuyPopup.product.id,
                    product_price_id: this.fastBuyPopup.product.select_type.id,
                    count: this.fastBuyPopup.product.select_count,
                });
                this.createOrder({
                    phone: this.payload.phone,
                    quick_order: 1,
                    delivery_method_id: 1,
                    products,
                }).then(() => {
                    this.$toasted.success(this.$t("successFastBuyCreate"));
                    this.changeFastBuyPopup(false);
                });
            }
        },
    },
};
