import type { ActionContext } from "vuex";
import type { RootState } from "@/store";
import { $http } from "@/utils/https";
import type { Product } from "@/types/shop";

interface State {
    cities: any;
    branches: any;
}

const state: State = {
    cities: null,
    branches: null,
}

const getters = {
    cities: (state: State) => state.cities,
    branches: (state: State) => state.branches,
}

const actions = {
    GET_CITY_LIST: async ({commit}: ActionContext<State, RootState>) => {
        commit('SET_CITIES_LIST', []);
        try {
            const response = await $http.get<{ data: Product }>(`v1/delivery/cities`);
            commit('SET_CITIES_LIST', response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {}
    },
    GET_BRANCH_LIST: async ({commit}: ActionContext<State, RootState>, payload: {city: string, search?: string}) => {
        commit('SET_BRANCHES_LIST', null);
        let url = `v1/delivery/branches?city=${payload.city}`;
        if (!!payload.search) {
            url += `&search=${payload.search}`;
        }
        try {
            const response = await $http.get<{ data: Product }>(url);
            commit('SET_BRANCHES_LIST', response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
        }
    },
}

const mutations = {
    SET_CITIES_LIST(state: State, value: any) {
        state.cities = value;
    },
    SET_BRANCHES_LIST(state: State, value: any) {
        state.branches = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
