import Vue from "vue";
import VueI18nManager from "vue-i18n-manager";
import store from "@/store";
import router from "@/router";

const proxy = {
    getTranslation: async lang => {
        return await store.dispatch(`setting/GET_TRANSLATIONS`, lang.key);
    },
};

Vue.use(VueI18nManager, {
    store,
    router,
    proxy,
    config: {
        defaultCode: "cz-CZ",
        languages: [
            {
                name: "Czech",
                title: "CZ",
                key: "cz",
                code: "cz-CZ",
                urlPrefix: "cz",
                translationKey: "cz",
            },
            {
                name: "English",
                title: "EN",
                key: "en",
                code: "en-US",
                urlPrefix: "en",
                translationKey: "en",
            },
            {
                name: "Russian",
                title: "RU",
                key: "ru",
                code: "ru-RU",
                urlPrefix: "ru",
                translationKey: "ru",
            },
        ],
        translations: proxy,
    },
});
