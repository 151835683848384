import { mapKeys, snakeCase } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { email, maxLength, minLength, required } from "vuelidate/lib/validators";
import PhoneMaskInput from "vue-phone-mask-input";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { RegisterMode } from "@/components/popups/registration";
import validation from "@/mixins/validation";
import { TheMask } from "vue-the-mask";
import {$http} from "../../../utils/https";

const typeOfActivityList = [
    { key: 'private_practice_cosmetologist', title: "selectPrivatePracticeCosmetologist" },
    { key: 'cosmetologist_injection_nurse', title: "selectCosmetologistInjectionNurse" },
    { key: 'beauty_salon_studio', title: "selectBeautySalonStudio" },
    { key: 'network_salon', title: "selectNetworkSalon" },
    { key: 'beauty_clinic', title: "selectBeautyClinic" },
    { key: 'network_clinic', title: "selectNetworkClinic" },
    { key: 'online_store', title: "selectOnlineStore" },
    { key: 'physical_store', title: "selectPhysicalStore" },
    { key: 'dealer', title: "selectDealer" },
    { key: 'masseur', title: "selectMasseur" },
    { key: 'doctor', title: "selectDoctor" },
    { key: 'dermatologist', title: "selectDermatologist" },
    { key: 'aesthetic_medicine_doctor', title: "selectAestheticMedicineDoctor" }
];

export default {
    name: "other-register",

    components: {
        PhoneMaskInput,
        TheMask
    },

    data() {
        return {
            agree: false,
            typeOfActivityList: {...typeOfActivityList},
            payload: {
                register_mode: RegisterMode.Other,
                assign_to: "",
                phone_country: "",
                email: "",
                name: "",
                secondName: "",
                phone: "",
                work: "",
                workPosition: "",
                social: "",
                // files: [],
                countryCode: null,
                country: null,
                city: null,
                selectedCountry: null,
                selectedCity: null,
                areaRef: "",
                // certificateNumber: '',
                workWithVatDisplay: null,
                vatNumber: null,
                icoNumber: null,
                icoConfirmToken: '',
                typeOfActivity: null,
                typeOfActivityList: null,
                comment: ''
            },

            showPassword: false,
            showRepeatPassword: false,
            showVatNumber: false,
            passwordErrors: [],
            repeatPasswordErrors: [],
            password: '',
            repeatPassword: '',
            phoneMaskCode: new Date().getTime(),
            countries: [],
            filterCountries: [],
            isValid: false,
            isValidTown: false,
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations() {
        const validation = {
            payload: {
                name: {
                    required,
                },
                secondName: {
                    required,
                },
                phone: {
                    required,
                    minLength: minLength(8),
                },
                country: {
                    required,
                },
                city: {
                    required,
                },
                selectedCountry: {
                    required,
                },
                selectedCity: {
                    required,
                },
                // certificateNumber: {
                //     required,
                // },
                workWithVatDisplay: {
                    required,
                },
                typeOfActivity: {
                    required,
                },
                email: {
                    required,
                    maxLength: maxLength(120),
                    email,
                },
                // icoNumber: {
                //     required,
                //     minLength: minLength(8),
                // }
            }
        }
        if (this.showVatNumber) {
            validation.payload.vatNumber = {
                required,
                // minLength: minLength(8),
            }
        }
        return validation;
    },
    watch: {
        agree() {
            this.$refs.agreeError.innerHTML = !this.agree ? this.$t("youMustAgreeToThePrivacyPolicy") : "";
        },
        "payload.countryCode"() {
            this.$refs.phonemask.$forceUpdate();
            this.phoneMaskCode = new Date().getTime();
            this.$refs.phonemask.$el.querySelector("input").value = "";
            this.payload.phone = "";
        },
        "password"() {
            this.passwordErrors = [];
        },
        "repeatPassword"() {
            this.repeatPasswordErrors = [];
        },
        "payload.workWithVatDisplay"() {
            this.payload.vatNumber = '';
        }
    },
    created() {
        this.payload.phone = this.verifyCodeResponse?.phone;
        document.getElementById("html").classList.remove("hide");
        this.onRequestTown(this.payload.city);
        this.getCountries();

        this.fetchCountries().then(res => {
            this.countries = res;
            this.filterCountries = res;
        });
    },
    computed: {
        ...mapGetters({
            loadingRegistration: "auth/loadingRegistration",
            agentCode: "auth/agentCode",
            towns: "order/towns",
            verifyCodeResponse: "auth/verifyCodeResponse",
            cityList: "country/cityList",
        }),
        nameErrors() {
            return this.computeErrors("payload", "name", {
                required: "validationRequired",
            });
        },
        secondNameErrors() {
            return this.computeErrors("payload", "secondName", {
                required: "validationRequired",
            });
        },
        phoneErrors() {
            return this.computeErrors("payload", "phone", {
                required: "validationRequired",
                minLength: { message: "validationMin", count: 8 },
            });
        },
        countryErrors() {
            return this.computeErrors("payload", "selectedCountry", {
                required: "validationRequired",
            });
        },
        workWithVatDisplayErrors() {
            return this.computeErrors("payload", "workWithVatDisplay", {
                required: "validationRequired",
            });
        },
        typeOfActivityErrors() {
            return this.computeErrors("payload", "typeOfActivity", {
                required: "validationRequired",
            });
        },
        cityErrors() {
            return this.computeErrors("payload", "selectedCity", {
                required: "validationRequired",
            });
        },
        emailErrors() {
            return this.computeErrors("payload", "email", {
                maxLength: { message: "validationMax", count: 120 },
                required: "validationRequired",
                email: "validationEmail",
            });
        },
        // workErrors() {
        //     return this.computeErrors("payload", "work", {
        //         required: "validationRequired",
        //     });
        // },
        // filesErrors() {
        //     return this.computeErrors("payload", "files", {
        //         required: "maxFileLoading",
        //     });
        // },
        // certificateNumberErrors() {
        //     return this.computeErrors("payload", "certificateNumber", {
        //         required: "validationRequired",
        //     });
        // },
        // icoNumberErrors() {
        //     return this.computeErrors("payload", "icoNumber", {
        //         required: "validationRequired",
        //         minLength: { message: "validationMin", count: 8 },
        //     });
        // },
        vatNumberErrors() {
            return this.computeErrors("payload", "vatNumber", {
                required: "validationRequired",
                // minLength: { message: "validationMin", count: 8 },
            });
        },
    },
    methods: {
        ...mapActions({
            registrations: "auth/REGISTRATION",
            getCountries: "auth/GET_COUNTRIES",
            fetchTowns: "order/NOVA_POSHTA_GET_TOWNS",
            fetchCountries: "country/GET_COUNTRY_LIST",
            fetchCities: "country/GET_CITY_LIST",
        }),
        ...mapMutations({
            changeRegisterAgent: "popups/CHANGE_SHOW_SUCCESS_AGENT_REGISTER",
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
        }),
        handleCountyInput(e) {
            if (e.length < 3) {
                this.filterCountries = this.countries.slice();
                return;
            }
            this.filterCountries = this.countries.filter(item => item.name.toLowerCase().includes(e.toLowerCase()));
        },

        handleCityInput(e) {
            if (e.length < 3) {
                this.fetchCities({ code: this.payload.countryCode }).then(res => console.log(res));
            }
            this.fetchCities({ code: this.payload.countryCode, search: e }).then(res => console.log(res));
        },

        handleSelectCountry(item) {
            this.payload.countryCode = item.code;
            this.payload.selectedCountry = item.name;
            this.payload.city = '';
            this.fetchCities({ code: item.code }).then(res => console.log(res));
        },
        handleSelectCity(item) {
            this.payload.selectedCity = item.name;
        },
        handleSelectVatValue(value) {
            this.showVatNumber = value.value;
        },
        handleSelectTypeOfActivity(value) {
            this.payload.typeOfActivity = value.map(item => item.title);
            this.payload.typeOfActivityList = value;
        },
        onRequestTown(val) {
            const formData = JSON.stringify({
                modelName: "Address",
                calledMethod: "getCities",
                methodProperties: {
                    FindByString: val,
                },
                apiKey: process.env.VUE_APP_NOVAPOSHTA_API_KEY,
            });
            this.fetchTowns(formData).then(() => {
                const currentTown = this.towns.find(e => e.Description === this.payload.city);

                console.log(currentTown);

                if (currentTown) {
                    this.payload.areaRef = currentTown.Area;
                } else {
                    this.payload.areaRef = "";
                }
            });
        },

        // handleFileChange(evt) {
        //     let arr = Array.from(evt.target.files);
        //     this.payload.files = this.payload.files.concat(arr);
        // },
        // removeFile(file) {
        //     const index = this.payload.files.findIndex(e => e.name === file.name);
        //     this.payload.files.splice(index, 1);
        // },
        transformData() {
            let parsedNumber = '';

            try {
                const phone = this.payload.phone;
                if (phone) {
                    parsedNumber = parsePhoneNumberFromString(phone.startsWith('+') ? phone : '+' + phone);
                }
            } catch (error) {
                console.error('Error parsing phone number:', error);
                parsedNumber = '';
            }

            if (parsedNumber) {
                this.payload.phone_country = parsedNumber.country;
            } else {
                this.payload.phone_country = "";
            }

            if (!this.showVatNumber) {
                this.payload.vatNumber = '';
            }

            this.payload.password = this.password;
            this.payload.password_confirmation = this.password;
            this.payload['workWithVat'] = this.showVatNumber === true ? 1 : 0;

            this.payload.confirm_token = this.verifyCodeResponse?.confirmToken;
            return mapKeys(this.payload, (value, key) => snakeCase(key));
        },

        parsFormData(obj) {
            let fd = new FormData();
            for (let i in obj) {
                if (i === "type_of_activity_list") {
                    fd.append(i, JSON.stringify(obj[i]));
                }
                if (i === "files") {
                    for (let j in obj[i]) {
                        fd.append(`files[${j}]`, obj[i][j]);
                    }
                } else {
                    fd.append(i, obj[i]);
                }
            }
            return fd;
        },
        async fetchIcoNumberCompanyInfo() {
            try {
                this.payload.icoConfirmToken = '';
                this.$v.payload.icoNumber.$reset();
                if (this.payload.icoNumber.length !== 8) return;

                const response = await $http.post('/v1/auth/verify-ico', {
                    "ico_number": this.payload.icoNumber
                })

                this.payload.icoConfirmToken = response.data['icoConfirmToken'];

                const country = this.countries.find(item => item.code === response.data.companyInfo.country)
                this.payload.country = country.name;
                this.payload.countryCode = response.data.companyInfo.country;
                this.payload.selectedCountry = country.name;
                this.payload.selectedCity = response.data.companyInfo.country;
                this.payload.city = response.data.companyInfo.city;
                this.payload.work = `${response.data.companyInfo.address}, ${response.data.companyInfo.commercialName}`
            } catch (error) {
                console.error('Error fetching ICO number company info:', error);
                this.handleRegistrationErrors(error)
                this.$v.payload.icoNumber.$touch()
            }
        },
        removeAttr(ref) {
            this.$refs[`${ref}`].removeAttribute("readonly");
        },
        async submit() {
            this.$refs.agreeError.innerHTML = !this.agree ? this.$t("youMustAgreeToThePrivacyPolicy") : "";

            this.$v.$touch();
            this.validationErrors = [];
            this.passwordErrors = [];
            this.repeatPasswordErrors = [];

            if (this.password.length < 8 ) this.passwordErrors.push(this.$t('validationMin').replace(':count', 8))
            if (this.repeatPassword.length < 8 ) this.repeatPasswordErrors.push(this.$t('validationMin').replace(':count', 8))
            if (this.password !== this.repeatPassword) this.repeatPasswordErrors.push(this.$t('validationSameAs'))


            if (this.$v.$invalid) {
                this.$toasted.error(this.$t("fillInAllRequiredFields"));
                return;
            }

            // if (!this.payload.files.length) {
            //     this.$toasted.error(this.$t("filesAreRequired"));
            //     return;
            // }

            if (!this.agree) {
                this.$toasted.error(this.$t("agreeToThePrivacyPolicy"));
                return;
            }

            if (this.passwordErrors.length || this.repeatPasswordErrors.length) return;

            try {
                await this.registrations(this.parsFormData(this.transformData()));

                this.changeRegisterAgent(true);
            } catch (error) {
                this.handleRegistrationErrors(error);
            }
        },
        handleRegistrationErrors(error) {
            if (!error?.data?.errors) {
                return;
            }

            // this.validationErrors = error.data.errors;

            const processErrors = ["email", "phone", "assignTo", "icoNumber", "vatNumber"];
            for (const processErrorsKey of processErrors) {
                const errors = error.data.errors[processErrorsKey];
                if (!errors || !errors[0]) {
                    continue;
                }

                this.$toasted.error(errors[0]);
            }
        },
    },
};
