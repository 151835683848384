import { mapGetters, mapMutations } from "vuex";
import login from "../popups/login/index.vue";
import registration from "../popups/registration/index.vue";
import reset from "../popups/reset/index.vue";
import restore from "../popups/restore/index.vue";
import addressesList from "../popups/addresses-list/index.vue";
import instagramPost from "../popups/instagram-post/index.vue";
import successRegister from "../popups/success-register/index.vue";
import anotherCosmetolog from "../popups/activate-another-cosmetolog/index.vue";
import fastBuy from "../popups/fast-buy/index.vue";
import defaultPrices from "../popups/default-prices/index.vue";
import formApplication from "../popups/form-application/index.vue";
import paymentInfo from "../popups/payment-info/index.vue";
import successRegisterAgent from "../popups/successRegisterAgent/index.vue";
import seminarRegistration from "@/components/popups/seminar-registration/index.vue";
import successRegisterSeminar from "@/components/popups/succes-seminar-register/index.vue";
import agentRegister from "@/components/popups/agent-register/index.vue";
import otherRegister from "@/components/popups/other-register/index.vue";
import successOrder from "@/components/popups/success-order/index.vue";
import basket from "@/components/popups/basket/index.vue";
import seminarDetails from "@/components/popups/seminar-details/index.vue";
import seminarInfo from "@/components/popups/seminar-info/index.vue";
import notifyWhenAvailable from "@/components/popups/notify-when-available/index.vue";
import smsPopup from "@/components/popups/sms-popup/index.vue";
import distributorRegister from "@/components/popups/distributor-register/index.vue";
import remainings from "../popups/remainings/index.vue";
import metodistPopup from "../metodist-popup/index.vue";
import langModal from "../lang-popup/index.vue";
import certificateError from "../popups/certificateError/index.vue";
import { PopupName } from "@/store/modules/popups";

export default {
    name: "hidden",
    components: {
        login,
        registration,
        reset,
        restore,
        addressesList,
        instagramPost,
        successRegister,
        anotherCosmetolog,
        fastBuy,
        defaultPrices,
        formApplication,
        paymentInfo,
        successRegisterAgent,
        seminarRegistration,
        successRegisterSeminar,
        agentRegister,
        otherRegister,
        successOrder,
        basket,
        seminarDetails,
        notifyWhenAvailable,
        smsPopup,
        distributorRegister,
        remainings,
        seminarInfo,
        metodistPopup,
        langModal,
        certificateError
    },
    data() {
        return {
            PopupName: PopupName,
        }
    },
    computed: {
        ...mapGetters({
            currentModal: "popups/currentModal",
            currentModalPayload: "popups/currentModalPayload",

            isProfessional: "profile/isProfessional",
            // TODO Merge
            showLangPopup: "system/showLangPopup",
        }),
    },
    methods: {
        ...mapMutations({
            closePopup: "popups/CLOSE_POPUP",
            changeLangModal: "system/SET_SHOW_LANG_POPUP",
        }),
        isPopupActive(name) {
            return this.currentModal === name;
        },
    },
};
