import { VueSlideToggle } from "vue-slide-toggle";
import { isClickableInSidebarMenu, isProcessableItem, routeForMenuItem } from "@/helpers";

export default {
    name: "burger-nav",
    components: {
        VueSlideToggle,
    },
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
    created() {},
    data() {
        return {
            open: false,
            openSubMenu: false,
        };
    },
    methods: {
        isClickableInSidebarMenu: isClickableInSidebarMenu,
        isProcessableItem: isProcessableItem,
        /**
         * @param {NavigationItem} navItem
         * @param {NavigationItem|null} parentNavItem
         * @return {Object}
         */
        routeForMenuItem(navItem, parentNavItem = null) {
            return this.$localize(routeForMenuItem(navItem, parentNavItem));
        },
    },
};
